/**
 * Helper utilities for Amelie.
 */
// Dependencies.
import exists from 'exists';
import has from 'lodash/has';
import { LOG_EVENTS, logEvent } from './log';

/**
 * Get a default value from the Amelie configuration.
 * @param thing
 * @returns {*}
 */
export function getDefaultConfig(thing) {
  let config = getConfig();
  if (exists(config) && exists(config[thing])) {
    config = config[thing];
  } else {
    // Get config recursively if using . syntax.
    const parts = thing.split('.');
    for (const part in parts) {
      if (exists(config[parts[part]])) {
        config = config[parts[part]];
      } else {
        return null;
      }
    }
  }

  return config;
}

/**
 * Retrieve or construct the config global for Amelie.
 * @returns {{}}
 */
function getConfig() {
  return (window.Amelie && window.Amelie.config)
    ? window.Amelie.config : (window.amelie_config) ? window.amelie_config : {};
}

/**
 * Retrieve the vendor board and any subboard from the config.
 * @returns {*}
 */
function getVendor() {
  const config = getConfig();
  const configured = config.vendor || 'fvrebgv';
  if (configured.indexOf('/') > 0) {
    const parts = configured.split('/');
    return {
      board: parts[0],
      subboard: parts[1],
    };
  } else {
    return {
      board: configured,
    };
  }
}

/**
 * Log error to console, if not production or test.
 * @param arg
 */
function debug(...arg) {
  if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
    // eslint-disable-next-line no-console
    console.error.apply(null, arg);
  }
}

function sendToGoogle(tag) {
  // Google adwords tag
  const googleAds = getDefaultConfig('googleAds');

  if (window.gtag
    && has(googleAds, 'conversionId')
    && has(googleAds, `conversionLabels.${tag}`)
    && googleAds.conversionId
    && googleAds.conversionLabels[tag]) {
    const { conversionId } = googleAds;
    const label = googleAds.conversionLabels[tag];
    const sendTo = `AW-${conversionId}/${label}`;
    window.gtag('event', 'conversion', { send_to: sendTo });

    logEvent({
      eventId: LOG_EVENTS.SEND_TO_GOOGLE,
      message: sendTo,
    });
  }
}

export const flattenMessages = (nestedMessages, prefix = '') => {
  // eslint-disable-next-line no-shadow
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const localMessages = messages;
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      localMessages[prefixedKey] = value;
    } else {
      Object.assign(localMessages, flattenMessages(value, prefixedKey));
    }

    return localMessages;
  }, {});
};

export default {
  getConfig,
  getDefaultConfig,
  getVendor,
  sendToGoogle,
  debug,
  flattenMessages,
};
