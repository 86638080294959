import request from 'superagent';
import { AUTHFUL_HOST } from '../constants';
import Utils from '../utils/utils';

export default {
  state: {},
  effects: () => ({
    async postHomeEvaluationForm(data) {
      const formData = {
        site_id: Utils.getDefaultConfig('siteID') || 1,
        // Contact
        first_name: data.firstname,
        last_name: data.lastname,
        email: data.email,
        // Address
        country_code: data.country,
        postal_code: data.postal_code,
        street_address_line_1: `${data.street_number} ${data.route}`,
        street_address_line_2: data.unitNumber,
        state_province_code: data.administrative_area_level_1,
        city: data.locality,
      };
      // only include if exists
      if (data.phoneNumber) {
        formData.phone_number = data.phoneNumber;
      }

      try {
        const response = await request
          .post(`${AUTHFUL_HOST}api/v1/home-evaluation-submissions`)
          .set('Content-Type', 'application/json')
          .send(JSON.stringify(formData));

        return !!response.ok
      } catch (err) {
        return false;
      }
    },
    async postHomeFinderForm(data) {
      const formData = {
        site_id: Utils.getDefaultConfig('siteID') || 1,
        // Contact
        first_name: data.firstname,
        last_name: data.lastname,
        email: data.email,
        // Home Finder
        areas_communities: data.city,
        description: data.city, // do we need to change the backend
        price: data.price,
        min_bedrooms: data.minBeds,
        min_bathrooms: data.minBaths,
      };
      // only include if exists
      if (data.phoneNumber) {
        formData.phone_number = data.phoneNumber;
      }

      try {
        const response = await request
          .post(`${AUTHFUL_HOST}api/v1/home-finder-submissions`)
          .set('Content-Type', 'application/json')
          .send(JSON.stringify(formData));

        return !!response.ok;
      } catch (err) {
        return false;
      }
    },
  }),
};
