import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Icon, Button } from '../AmelieUI';
import AmelieFABForm from '../AmelieFABForm/AmelieFABForm';
import { LEAD_CAPTURE_BUBBLE } from '../../utils/leads';
import Utils from '../../utils/utils';
import './AmelieFAB.scss';

export class AmelieFAB extends Component {
  constructor() {
    super();

    this.state = {
      showComponent: false,
      showForm: false,
      source: LEAD_CAPTURE_BUBBLE,
      additionalNotes: null,
    };

    setTimeout(() => {
      this.setState({
        showComponent: true,
      });
    }, 2000);
  }

  componentDidMount() {
    const fabElement = document.getElementById('amelie-fab');
    if (fabElement) {
      fabElement.addEventListener('fab-click', this.handleCustomEventFabClick);
    }
  }

  componentWillUnmount() {
    const fabElement = document.getElementById('amelie-fab');
    if (fabElement) {
      fabElement.removeEventListener('fab-click', this.handleCustomEventFabClick);
    }
  }

  handleCustomEventFabClick = (e) => {
    const { dispatch } = this.props;

    const { data } = e.detail;

    const source = data['data-lead-source'] || LEAD_CAPTURE_BUBBLE;
    const additionalNotes = data['data-connect'];

    // reopen the form just in case it's already open
    this.setState({
      showForm: false,
      source,
      additionalNotes,
    }, () => {
      this.setState({
        showForm: true,
      }, () => {
        dispatch.leadCapture.hideBubbleMessage();
      });
    });
  };

  handleShowClick = () => {
    const { dispatch } = this.props;
    this.setState({
      showForm: true,
      source: LEAD_CAPTURE_BUBBLE,
    }, () => {
      dispatch.leadCapture.hideBubbleMessage();
    });
  };

  handleDismissClick = () => {
    const { dispatch } = this.props;
    this.setState({
      showForm: false,
    }, () => {
      dispatch.leadCapture.hideBubbleMessage();
    });
  };

  renderMessage() {
    const { intl } = this.props;
    const clientName = Utils.getDefaultConfig('clientName') || '';
    const messageBody = Utils.getDefaultConfig('leadCaptureBubble.popup.text') || intl.formatMessage({ id: 'amelieFAB.message.body' });
    const messageCTA = Utils.getDefaultConfig('leadCaptureBubble.form.CTA') || `${intl.formatMessage({ id: 'amelieFAB.message.message' })} ${clientName}`;

    return (
      <div className="amelie-fab-message">
        <button
          className="amelie-fab-message-dismiss"
          icon="close"
          onClick={this.handleDismissClick}
          aria-label="Close"
        >
          <Icon type="close" />
        </button>
        <div className="amelie-fab-message-body">
          {messageBody}
        </div>
        <Button
          className="amelie-fab-message-button"
          type="primary"
          small
          onClick={this.handleShowClick}
        >
          {messageCTA}
        </Button>
      </div>
    );
  }

  render() {
    const { showForm, showComponent, source, additionalNotes } = this.state;
    const { visibleBubbleMessage } = this.props;
    const avatarImage = Utils.getDefaultConfig('avatarImage');

    const fabStyle = {
      backgroundImage: showForm || !avatarImage ? 'none' : `url(${avatarImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    };

    return (
      <>
        {showForm && (
          <AmelieFABForm
            onClose={this.handleDismissClick}
            source={source}
            additionalNotes={additionalNotes}
          />
        )}
        {showComponent && (
          <>
            { visibleBubbleMessage && this.renderMessage() }
            <div
              className={classNames('amelie-fab', {
                'amelie-fab-form-show': showForm,
              })}
              style={fabStyle}
              onClick={showForm ? this.handleDismissClick : this.handleShowClick}
            >
              { showForm ? (
                <Icon type="close" className="amelie-fab-icon-close" />
              ) : (
                !avatarImage && <Icon type="send" className="amelie-fab-icon-send" />
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ leadCapture }) => ({
  visibleBubbleMessage: leadCapture.visibleBubbleMessage,
});

const enhanced = compose(injectIntl, connect(mapStateToProps));
export default enhanced(AmelieFAB);
