import request from 'superagent';
import sortBy from 'lodash/sortBy';
import has from 'lodash/has';
import Utils from '../utils/utils';
import { AUTHFUL_HOST } from '../constants';

const initialState = {
  isSaving: false,
  isSaveCompleted: false,
  isSaveSuccess: false,
  isLoading: false,
  formData: {
    countryStateData: [],
    homeTypes: [],
    cache: {},
  },
};

export default {
  state: initialState,
  reducers: {
    startFetchData: (state) => ({ ...state, isLoading: true }),
    failedFetchData: (state) => ({
      ...state,
      isFailed: true,
      isLoading: false,
    }),
    successFetchData: (state, payload) => ({
      ...state,
      isLoading: false,
      isFailed: false,
      formData: {
        ...state.formData,
        ...payload,
      },
    }),
    startSubmit: (state, payload) => ({
      ...state,
      isSaving: true,
      formData: {
        ...state.formData,
        cache: payload,
      },
    }),
    successSubmited: (state) => ({
      ...state,
      isSaving: false,
      isSaveSuccess: true,
      isSaveCompleted: true,
    }),
    failedSubmited: (state) => ({
      ...state,
      isSaving: false,
      isSaveSuccess: false,
      isSaveCompleted: true,
    }),
  },
  effects: (dispatch) => ({
    getCountriesList() {
      return request.get(`${AUTHFUL_HOST}api/v1/countries`);
    },
    getStatesList() {
      return request.get(`${AUTHFUL_HOST}api/v1/states`);
    },
    getHomeTypes() {
      return request.get(`${AUTHFUL_HOST}api/v1/home-types`);
    },
    async loadInitialFormData() {
      try {
        dispatch.evaluationForm.startFetchData();

        const countriesResponse = await this.getCountriesList();
        const statesResponse = await this.getStatesList();
        const homeTypesResponse = await this.getHomeTypes();

        const countries = countriesResponse.body;
        const states = statesResponse.body;
        const countryStateData = [];

        Object.keys(countries).forEach((countryKey) => {
          const countryName = countries[countryKey];
          const countryStates = states[countryKey];

          countryStateData.push({
            country: countryName,
            countryKey,
            states: countryStates,
          });
        });

        dispatch.evaluationForm.successFetchData({
          countryStateData: sortBy(countryStateData, 'country'),
          homeTypes: homeTypesResponse.body.data,
        });
      } catch (error) {
        dispatch.evaluationForm.failedFetchData();
      }
    },
    async saveFormData(data) {
      dispatch.evaluationForm.startSubmit(data);

      const saveData = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_number: data.phone,
        street_address_line_1: data.street1,
        street_address_line_2: data.street2,
        city: data.city,
        state_province_code: data.state,
        postal_code: data.postalcode,
        country_code: data.country,
        site_id: Utils.getDefaultConfig('siteID') || 1,
        home_type_id: data.property_type,
        home_type_other: data.property_type_other,
        bedrooms: data.bedroom,
        bathrooms: data.bath,
        square_footage: data.sqft,
        features: data.details,
        reason: (has(data, 'eval_reason_other')) ? `${data.eval_reason} (${data.eval_reason_other})` : data.eval_reason,
      };

      try {
        const response = await request
          .post(`${AUTHFUL_HOST}api/v1/home-evaluation-submissions`)
          .query(saveData);

        if (!response.ok) {
          dispatch.evaluationForm.failedSubmited();
        }

        dispatch.evaluationForm.successSubmited();
      } catch (err) {
        dispatch.evaluationForm.failedSubmited();
      }
    },
  }),
};
