/**
 * AmelieExpandedDetails
 * @package   rb-amelie
 * @author    Deiva Magalhaes <deiva@avenuehq.com>
 * @license   None
 * @link      https://avenuehq.com
 * @copyright    2017 AvenueHQ
 */
// Dependencies.
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AmelieSignInPrompt from '../AmelieSignInPrompt/AmelieSignInPrompt';
import AmelieEnhancedFields from '../AmelieEnhancedFields/AmelieEnhancedFields';
import * as authActions from '../../redux/auth';

// Define component class.
class AmelieExpandedDetails extends PureComponent {
  // Render the component.
  render() {
    const { auth, showForm, fields, listing } = this.props;
    if (!auth.userToken) {
      return (
        <AmelieSignInPrompt
          auth={auth}
          showForm={showForm}
        />
      );
    }
    return (
      <div>
        {auth.userToken && (
          <AmelieEnhancedFields fields={fields} listing={listing} location="main" />
        )}
      </div>
    );
  }
}

// Property validation.
AmelieExpandedDetails.propTypes = {
  // Properties.
  auth: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  listing: PropTypes.object.isRequired,

  // Functions.
};

const mapStateToProps = ({ auth, fields, listing }) => ({
  auth,
  fields,
  listing,
});

const mapDispatchToProps = (dispatch) => {
  const actionCreators = {
    ...authActions,
  };
  return bindActionCreators(actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AmelieExpandedDetails);

// For tests.
export { AmelieExpandedDetails as PureAmelieExpandedDetails };
