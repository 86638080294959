import React, { forwardRef, useContext, createContext } from 'react';
import styles from './Radio.module.scss';

const RadioContext = createContext({});

const Radio = forwardRef(({
  children,
  value,
  name,
  defaultChecked,
  disabled,
  onChange,
}, ref) => {
  const group = useContext(RadioContext);
  const handleChange = (e) => {
    if (group.onChange) {
      group.onChange(String(e.target.value));
    }

    if (onChange) {
      onChange();
    }
  };

  return (
    <label className={styles.radio}>
      <input
        ref={ref}
        type="radio"
        value={value}
        name={name}
        defaultChecked={defaultChecked}
        disabled={disabled || group.disabled}
        checked={group.value !== undefined ? value === group.value : undefined}
        onChange={handleChange}
      />
      <span>{children}</span>
    </label>
  );
});
Radio.displayName = 'Radio';

const RadioGroup = forwardRef(({ children, className, ...rest }, ref) => {
  return (
    <div className={className}>
      <RadioContext.Provider ref={ref} value={rest}>{children}</RadioContext.Provider>
    </div>
  );
});
RadioGroup.displayName = 'RadioGroup';

Radio.Group = RadioGroup;

export default Radio;
