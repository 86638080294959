import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import Icon from '../Icon/Icon';
import styles from './Button.module.scss';

function Button(props) {
  const {
    children,
    className,
    disabled,
    htmlType,
    icon,
    iconClassName,
    loading,
    small,
    type,
    onClick,
  } = props;

  const handleClick = (e) => {
    if (loading) {
      return;
    }

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <button
      {...omit(props, ['htmlType', 'iconClassName', 'loading', 'small', className, onClick])}
      type={htmlType}
      className={classNames(className, styles.button, {
        [styles.primary]: type === 'primary',
        [styles.small]: small,
        [styles.loading]: loading,
        [styles.disabled]: disabled,
      })}
      onClick={handleClick}
    >
      { icon && (
        <Icon
          type={icon}
          className={classNames(iconClassName, styles.icon)}
        />
      )}
      <span className={styles.buttonText}>
        { children }
      </span>
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.any,
  disabled: PropTypes.bool,
  htmlType: PropTypes.string,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  loading: PropTypes.bool,
  small: PropTypes.bool,
  type: PropTypes.string,
};

Button.defaultProps = {
  className: null,
  disabled: false,
  htmlType: null,
  icon: null,
  iconClassName: null,
  loading: false,
  small: false,
  type: 'default',
};

export default Button;
