import React, { useEffect, useState } from 'react';
import Alert from '../AmelieUI/Alert/Alert';
import './LogoutConfirmation.scss';

function LogoutConfirmation() {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  if (!show) {
    return null;
  }

  return (
    <div className="logout-confirmation">
      <Alert 
        type="success" 
        message="You have been successfully signed out" 
      />
    </div>
  );
}

export default LogoutConfirmation;