import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Alert.module.scss';

function Alert({ 
  type,
  message,
  className,
}) {
  return (
    <div
      className={classNames(className, styles.alert, {
        [styles.error]: type === 'error',
        [styles.success]: type === 'success',
      })}
    >
      {message}
    </div>
  );
}

Alert.propTypes = {
  type: PropTypes.oneOf(['error', 'success']),
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Alert;
