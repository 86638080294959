// Dependencies.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../AmelieUI';
import Utils from '../../utils/utils';

// Define component class.
class AmelieFavoriteIcon extends Component {
  constructor(props) {
    super();

    this.state = {
      favorite: props.active ? 'heart' : 'heart-o',
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      favorite: props.active ? 'heart' : 'heart-o',
    });
  }

  handleMouseOver = () => {
    const { active } = this.props;
    this.setState(active ? { favorite: 'heart-o' } : { favorite: 'heart' });
  };

  handleMouseLeave = () => {
    const { active } = this.props;
    this.setState(active ? { favorite: 'heart' } : { favorite: 'heart-o' });
  };

  handleIconClick = (e) => {
    const { toggleFavorite, id } = this.props;
    e.preventDefault();
    e.stopPropagation();
    toggleFavorite(id);
  };

  render() {
    const { favorite } = this.state;
    const heartColor = Utils.getDefaultConfig('iconColor');

    return (
      <div
        onClick={this.handleIconClick}
        onMouseOver={this.handleMouseOver}
        onFocus={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
      >
        <Icon type={favorite} style={{ fontSize: 32, color: heartColor, cursor: 'pointer' }} />
      </div>
    );
  }
}

// Property validation.
AmelieFavoriteIcon.propTypes = {
  // State.
  active: PropTypes.bool, // eslint-disable-line

  // Component properties.
  id: PropTypes.string.isRequired,

  // Functions.
  toggleFavorite: PropTypes.func.isRequired,
};

export default AmelieFavoriteIcon;
