// fields.js

/**
 * Dependencies.
 */

import request from 'superagent';
import lol from '../api/lol';
import Utils from '../utils/utils';

/**
 * Actions.
 */

export const LOAD_FIELDS = 'rb-amelie/fields/LOAD_FIELDS';

/**
 * Local variables.
 */

const maxAttempts = 3;
let fieldsFailures = 0;

/**
 * Reducer.
 */

const initialState = [];

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_FIELDS: {
      return action.fields;
    }

    default: {
      return state;
    }
  }
}

/**
 * Action creators.
 */

export function loadFields() {
  return (dispatch, getState) => {
    if (fieldsFailures < maxAttempts) {
      const state = getState();
      const { auth } = state;

      if (auth.userToken !== null) {
        const lolURL = lol.buildFieldsURL();

        // Call to LOL API to retrieve fields by license and board.
        return request.get(lolURL)
          .timeout({
            response: 10000,
            deadline: 15000,
          })
          .then((json) => {
            const { body } = json;
            const fields = [];

            for (const property in body.bundle) {
              if (Object.prototype.hasOwnProperty.call(body.bundle, property)) {
                const field = body.bundle[property];
                fields.push(field);
              }
            }
            dispatch({
              type: LOAD_FIELDS,
              fields,
            });
            // Success! Clear the failure count.
            fieldsFailures = 0;
          })
          .catch((error) => {
            fieldsFailures += 1;
            Utils.debug(error, fieldsFailures);
            // Goonies never say die!
            dispatch(loadFields());
          });
      }
    } else {
      // Too many failures. Log and reset.
      Utils.debug(`Call to LOL API failed after ${maxAttempts} attempts.`);
      fieldsFailures = 0;
    }
  };
}
