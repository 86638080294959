import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { AddressSearchInput, Button, Form, Input, Alert } from '../AmelieUI';
import { getValidationMessage } from '../../utils/leads';

import './AmelieLandingAddressForm.scss';

// Form validation tweak. This prevents displaying validation message on the UI.
const ERROR_MESSAGE = ' ';

// antd's Form must be class component
class AmelieLandingAddressForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
  };

  state = {
    hasError: null,
    address: null,
  };

  handleSubmit = (e) => {
    const { onSubmit, form } = this.props;
    e.preventDefault();

    form.validateFieldsAndScroll((err, values) => {
      const { address } = this.state;
      const {
        administrative_area_level_1: state,
        country,
        postal_code: postalCode,
        route,
        street_number: streetNumber,
      } = address;

      const checkAddress = state && country && postalCode && route && streetNumber;

      if (err || !checkAddress) {
        return this.setState({
          hasError: true,
        });
      }

      if (onSubmit) {
        onSubmit({ ...values, ...address });
      }
    });
  };

  handleAddressSelect = (address) => {
    this.setState({ hasError: false, address });
  };

  render() {
    const { intl, form, title } = this.props;
    const { hasError } = this.state;
    const { getFieldDecorator } = form;
    const { formatMessage } = intl;

    return (
      <Form layout="inline" onSubmit={this.handleSubmit} className="amelie-landing-address-form">
        <h2 className="amelie-landing-address-form-title">
          {title}
        </h2>
        <Form.Item className="amelie-landing-address-form-item">
          {getFieldDecorator('address', {
            rules: [{
              required: true,
              message: ERROR_MESSAGE,
            }],
          })(
            <AddressSearchInput
              placeholder={formatMessage({ id: 'amelieLanding.form.placeholderAddress' })}
              iconType="pin"
              onSelect={this.handleAddressSelect}
              className="amelie-landing-address-form-address"
            />
          )}
        </Form.Item>
        <Form.Item className="amelie-landing-address-form-item">
          {getFieldDecorator('unitNumber')(
            <Input
              placeholder={formatMessage({ id: 'amelieLanding.form.placeholderUnitNumber' })}
              className="amelie-landing-address-form-unit"
            />
          )}
        </Form.Item>
        {hasError && (
          <Alert
            type="error"
            className="amelie-landing-address-form-error"
            message={getValidationMessage('address', false)}
          />
        )}
        <div>
          <Button
            type="primary"
            className="amelie-landing-address-form-submit"
            htmlType="submit"
          >
            {formatMessage({ id: 'amelieLanding.form.estimate' })}
          </Button>
        </div>
      </Form>
    );
  }
}

const enhanced = compose(
  Form.create(),
  injectIntl
);

export default enhanced(AmelieLandingAddressForm);
