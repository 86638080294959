import React, { forwardRef } from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import styles from './Select.module.scss';

const Select = forwardRef(({
  className,
  children,
  showArrow,
  value,
  options,
  onChange,
  ...rest
}, ref) => {
  const getValue = options.find((item) => item.value === value);

  const handleChange = (item) => {
    if (onChange) {
      onChange(item.value);
    }
  };

  return (
    <ReactSelect
      {...rest}
      ref={ref}
      options={options}
      value={getValue}
      onChange={handleChange}
      isSearchable={false}
      className={classNames(styles.select, className)}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: '#f6f6f6',
          paddingLeft: 14,
          fontSize: 15,
          height: 56,
          borderColor: state.isFocused ? '#3b4248' : '#f6f6f6',
          ':hover': {
            borderColor: '#3b4248',
          },
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          display: 'none',
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          display: showArrow ? 'flex' : 'none',
        }),
        option: (baseStyles) => ({
          ...baseStyles,
          color: '#3b4248',
          padding: '0 0 0 24px',
          lineHeight: '32px',
        }),
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: '#eee',
          primary: '#f6f6f6',
        },
      })}
    >
      {children}
    </ReactSelect>
  );
});
Select.displayName = 'Select';

Select.defaultProps = {
  showArrow: true,
};

export default Select;
