import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';

import AmelieHomeFinderForm from '../AmelieHomeFinderForm/AmelieHomeFinderForm';
import Utils from '../../utils/utils';
import './AmelieHomeFinder.scss';

class AmelieHomeFinder extends Component {
  constructor(props) {
    super(props);
    this.feedbackMessageRef = createRef();
  }

  componentDidUpdate(prevProps) {
    const { formSubmitted: prevFormSubmitted } = prevProps;
    const { formSubmitted: curFormSubmitted } = this.props;

    if (!prevFormSubmitted && curFormSubmitted) {
      Utils.sendToGoogle('homeFinder');

      window.scrollTo({
        // Add an extra margin before the message content
        top: this.feedbackMessageRef.current.offsetTop - 200,
        behavior: 'smooth',
      });
    }
  }

  render() {
    const { intl, formSubmitted, cache } = this.props;
    return (
      <div className="amelie-home-finder">
        {formSubmitted ? (
          <div
            ref={this.feedbackMessageRef}
            className="amelie-home-finder-message"
          >
            <h2>
              {intl.formatMessage({ id: 'amelieHomeFinder.form.alert.success' }, { name: cache.first_name })}
            </h2>
          </div>
        ) : (
          <>
            <div className="amelie-home-finder-header">
              <h2>
                {intl.formatMessage({ id: 'amelieHomeFinder.title' })}
              </h2>
              <p>
                {intl.formatMessage({ id: 'amelieHomeFinder.instruction' })}
              </p>
            </div>
            <AmelieHomeFinderForm />
          </>
        )}
      </div>
    );
  }
}

AmelieHomeFinder.propTypes = {
  intl: PropTypes.object.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
  cache: PropTypes.object.isRequired,
};

const mapState = ({ homeFinder }) => ({
  formSubmitted: Boolean(homeFinder.isSaveCompleted && homeFinder.isSaveSuccess),
  cache: homeFinder.formData.cache,
});

const enhanced = compose(
  connect(mapState),
  injectIntl
);

export default enhanced(AmelieHomeFinder);
