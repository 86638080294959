import store from '../store';
import { mockSignIn } from '../redux/auth';

/**
 * Development utilities exposed to window for console usage
 */
export const devUtils = {
  mockLogin(userName) {
    store.dispatch(mockSignIn(userName));
  },
  getState() {
    return store.getState();
  },
  dispatch(action) {
    return store.dispatch(action);
  },
};

// Always expose dev utils and store utilities
window.devUtils = devUtils;
window.getState = devUtils.getState;
window.dispatch = devUtils.dispatch;