import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

class AmelieSignOutLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleClick = () => {
    this.setState({
      loading: true,
    });
    setTimeout(() => this.completeSignOut(), 300);
  };

  completeSignOut = () => {
    const { signOut } = this.props;
    signOut();
    this.setState({
      loading: false,
    });
  };

  render() {
    const { auth, intl } = this.props;
    const { loading } = this.state;
    const loader = <div className="loader" />;

    if (loading) {
      return loader;
    }

    return (
      <>
        {auth.userToken && (
          // Must be a link because of WP.
          <a className="menu-button" onClick={() => this.handleClick()} onKeyDown={() => this.handleClick()}>
            {intl.formatMessage({ id: 'amelieSignOutLink.signOut' })}
          </a>
        )}
      </>
    );
  }
}

AmelieSignOutLink.propTypes = {
  // Properties.
  auth: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,

  // Functions.
  signOut: PropTypes.func.isRequired,
};

export default injectIntl(AmelieSignOutLink);
