// Dependencies.
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import castArray from 'lodash/castArray';
import AmelieSignInForm from '../AmelieSignInForm/AmelieSignInForm';
import LogoutConfirmation from '../LogoutConfirmation/LogoutConfirmation';
import * as authActions from '../../redux/auth';
import * as favoritesActions from '../../redux/favorites';
import * as fieldsActions from '../../redux/fields';
import * as listingActions from '../../redux/listing';
import Utils from '../../utils/utils';
import './AmelieMain.scss';

// Define component class.
class AmelieMain extends PureComponent {
  state = {
    showLogoutConfirmation: false,
  };

  updateLoggedInClass = (isLoggedIn) => {
    if (isLoggedIn) {
      document.body.classList.add('amelie-logged-in');
    } else {
      document.body.classList.remove('amelie-logged-in');
    }
  }

  componentDidMount() {
    // Add global handler for Facebook in-app browser autofill
    window._AutofillCallbackHandler = window._AutofillCallbackHandler || function(){};
    
    const { query, signIn, auth } = this.props;
    
    // Handle token in URL
    if (query.token) {
      signIn(query.token);
    }

    // Set initial logged-in state
    this.updateLoggedInClass(Boolean(auth.userToken));
    
    // Initialize features if already logged in
    if (auth.userToken) {
      this.initializeFeatures();
    }
  }

  componentDidUpdate(prevProps) {
    const { auth } = this.props;
    
    // Handle auth state changes
    if (prevProps.auth.userToken !== auth.userToken) {
      this.updateLoggedInClass(Boolean(auth.userToken));
      
      // Initialize features on login
      if (!prevProps.auth.userToken && auth.userToken) {
        this.initializeFeatures();
      }
    }
  }

  componentWillUnmount() {
    const { auth } = this.props;
    // Only remove logged-in class if this instance added it
    if (auth.userToken) {
      document.body.classList.remove('amelie-logged-in');
    }
  }

  initializeFeatures() {
    const { loadFields, loadListing, loadFavorites, auth } = this.props;

    if (auth.userToken) {
      if (Utils.getDefaultConfig('enhancedEnabled')) {
        loadFields();
        loadListing();
      }
      if (Utils.getDefaultConfig('favoritesEnabled')) {
        loadFavorites();
      }
    }
  }

  buildRouter(query, doReplace = true) {
    const { location, history } = this.props;
    // Build query string with ordered params.
    // Update the URL.
    const newURL = `${location.pathname}?${query}`;
    if (doReplace) {
      history.replace(newURL);
    } else {
      history.push(newURL);
    }
  }

  removeFromRouter(keysToRemove) {
    const keys = castArray(keysToRemove);

    // Strip the keys from the query string.
    const uri = new URL(window.location.href);
    keys.forEach((key) => {
      uri.searchParams.delete(key);
    });
    // Build query string with ordered params.
    this.buildRouter(uri.searchParams.toString(), false);
  }

  handleSignOut = () => {
    const { signOut } = this.props;
    signOut();
    this.setState({ showLogoutConfirmation: true });
  };

  // Render the component.
  render() {
    const { 
      auth,
      query,
      hideForm,
      signIn,
      signInError,
      loadFields,
      loadListing,
      loadFavorites,
      setFavorite,
      getVowTermsOfService,
    } = this.props;
    const { showLogoutConfirmation } = this.state;

    // Only render what's necessary when logged in during PSLC flow
    if (query.actionFrom === 'pslc' && auth.userToken) {
      return showLogoutConfirmation ? <LogoutConfirmation /> : null;
    }

    // Regular render for non-PSLC context
    return (
      <div>
        {showLogoutConfirmation && <LogoutConfirmation />}
        {!auth.userToken && query.actionFrom !== 'pslc' && (
          <AmelieSignInForm
            auth={auth}
            userToken={query.token}
            favoriteMlsListingID={query.favorite}
            hideForm={hideForm}
            signIn={signIn}
            signInError={signInError}
            signOut={this.handleSignOut}
            loadFields={loadFields}
            loadListing={loadListing}
            loadFavorites={loadFavorites}
            setFavorite={setFavorite}
            removeFromRouter={(keys) => this.removeFromRouter(keys)}
            getVowTermsOfService={getVowTermsOfService}
          />
        )}
      </div>
    );
  }
}

// Property validation.
AmelieMain.propTypes = {
  // Properties.
  auth: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,

  // Functions.
  hideForm: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  signInError: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  loadFields: PropTypes.func.isRequired,
  loadListing: PropTypes.func.isRequired,
  loadFavorites: PropTypes.func.isRequired,
  setFavorite: PropTypes.func.isRequired,
  getVowTermsOfService: PropTypes.func.isRequired,

  // External guff.
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

// Context.
AmelieMain.contextTypes = {
  router: PropTypes.object,
};

const mapStateToProps = ({ auth }, ownProps) => ({
  auth,
  query: queryString.parse(ownProps.location.search),
});

const mapDispatchToProps = (dispatch) => {
  const actionCreators = {
    ...authActions,
    ...favoritesActions,
    ...fieldsActions,
    ...listingActions,
  };
  return bindActionCreators(actionCreators, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AmelieMain));

// For tests.
export { AmelieMain as PureAmelieMain };
