import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

import { Form, Input, Button, Alert, Select } from '../AmelieUI';
import AmelieDisclaimer from 'components/AmelieDisclaimer/AmelieDisclaimer';
import Utils from '../../utils/utils';

import './AmelieLandingFinderForm.scss';

// Form validation tweak. This prevents displaying validation message on the UI.
const ERROR_MESSAGE = ' ';

// antd Form must be a class component
class AmelieLandingFinderForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
  };

  state = {
    hasError: false,
  };

  handleSubmit = (e) => {
    const { onSubmit, form } = this.props;
    e.preventDefault();

    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return this.setState({
          hasError: true,
        });
      }

      if (onSubmit) {
        onSubmit(values);
      }
    });
  };

  render() {
    const { intl, form, title } = this.props;
    const { hasError } = this.state;
    const { getFieldDecorator } = form;
    const { formatMessage } = intl;

    const leadCaptureRequirePhone = Utils.getDefaultConfig('leadCaptureRequirePhone');

    return (
      <Form layout="inline" onSubmit={this.handleSubmit} className="amelie-landing-finder-form">
        <h2 className="amelie-landing-form-title">{title}</h2>
        <div className="amelie-landing-form-group">
          <Form.Item className="amelie-landing-form-item">
            {getFieldDecorator('city', {
              rules: [{
                required: true,
                message: ERROR_MESSAGE,
              }],
            })(
              <Input
                placeholder={formatMessage({ id: 'amelieLanding.form.placeholderCity' })}
              />
            )}
          </Form.Item>
          <Form.Item className="amelie-landing-form-item">
            {getFieldDecorator('price', {
              rules: [{
                required: true,
                message: ERROR_MESSAGE,
              }],
            })(
              <Select
                placeholder="Price range"
                options={[
                  { value: '2500000', label: '$2,500,000+' },
                  { value: '2000000-2500000', label: '$2,000,000 - $2,500,000' },
                  { value: '1500000-2000000', label: '$1,500,000 - $2,000,000' },
                  { value: '1000000-1500000', label: '$1,000,000 - $1,500,000' },
                  { value: '750000-1000000', label: '$750,000 - $1,000,000' },
                  { value: '500000-750000', label: '$500,000 - $750,000' },
                  { value: '350000-500000', label: '$350,000 - $500,000' },
                  { value: '100000-350000', label: '$100,000 - $350,000' },
                ]}
              />
            )}
          </Form.Item>
          <div className="column-50 column-50-mob">
            <Form.Item className="amelie-landing-form-item">
              {getFieldDecorator('minBeds', {
                rules: [{
                  required: true,
                  message: ERROR_MESSAGE,
                }],
              })(
                <Select
                  placeholder="Bedrooms"
                  options={[
                    { value: '0', label: 'Any' },
                    { value: '1', label: '1+' },
                    { value: '2', label: '2+' },
                    { value: '3', label: '3+' },
                    { value: '4', label: '4+' },
                  ]}
                />
              )}
            </Form.Item>
            <Form.Item className="amelie-landing-form-item">
              {getFieldDecorator('minBaths', {
                rules: [{
                  required: true,
                  message: ERROR_MESSAGE,
                }],
              })(
                <Select
                  placeholder="Bathrooms"
                  options={[
                    { value: '0', label: 'Any' },
                    { value: '1', label: '1+' },
                    { value: '2', label: '2+' },
                    { value: '3', label: '3+' },
                    { value: '4', label: '4+' },
                  ]}
                />
              )}
            </Form.Item>
          </div>
        </div>
        <h2 className="amelie-landing-form-title">Contact details</h2>
        <div className="amelie-landing-form-group">
          <div className="column-50 column-50-mob">
            <Form.Item className="amelie-landing-form-item">
              {getFieldDecorator('firstname', {
                rules: [{
                  required: true,
                  message: ERROR_MESSAGE,
                }],
              })(
                <Input
                  placeholder={formatMessage({ id: 'amelieLanding.form.placeholderFirstName' })}
                />
              )}
            </Form.Item>
            <Form.Item className="amelie-landing-form-item">
              {getFieldDecorator('lastname', {
                rules: [{
                  required: true,
                  message: ERROR_MESSAGE,
                }],
              })(
                <Input
                  placeholder={formatMessage({ id: 'amelieLanding.form.placeholderLastName' })}
                />
              )}
            </Form.Item>
          </div>
          <Form.Item className="amelie-landing-form-item">
            {getFieldDecorator('email', {
              rules: [{
                required: true,
                type: 'email',
                message: ERROR_MESSAGE,
              }],
            })(
              <Input
                type="email"
                placeholder={formatMessage({ id: 'amelieLanding.form.placeholderEmailAddress' })}
              />
            )}
          </Form.Item>
          <Form.Item className="amelie-landing-form-item">
            {getFieldDecorator('phoneNumber', {
              rules: [
                { required: leadCaptureRequirePhone, message: ERROR_MESSAGE },
                { pattern: /^\d{3}-\d{3}-\d{4}$/, message: ERROR_MESSAGE },
              ],
            })(
              <Input.Mask
                placeholder={leadCaptureRequirePhone ? 'Phone number' : 'Phone number (optional)'}
                mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              />
            )}
          </Form.Item>
        </div>
        {hasError && (
          <Alert
            type="error"
            className="amelie-landing-finder-form-error"
            message={intl.formatMessage({ id: 'amelieLanding.form.validationAllRequired' })}
          />
        )}
        <AmelieDisclaimer theme="dark" />
        <Button
          type="primary"
          htmlType="submit"
          className="amelie-landing-finder-form-submit"
        >
          Submit
        </Button>
      </Form>
    );
  }
}

const enhanced = compose(
  Form.create(),
  injectIntl
);

export default enhanced(AmelieLandingFinderForm);
