import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { CookiesProvider } from 'react-cookie';
import store from './store';
import { getDefaultConfig, flattenMessages } from './utils/utils';
import { registerLog } from './utils/log';

// Components
import AmelieMain from './components/AmelieMain/AmelieMain';
import AmelieExpandedDetails from './components/AmelieExpandedDetails/AmelieExpandedDetails';
import AmelieSidebar from './components/AmelieSidebar/AmelieSidebar';
import AmelieFavoriteListing from './components/AmelieFavoriteListing/AmelieFavoriteListing';
import AmelieMenu from './components/AmelieMenu/AmelieMenu';
import AmelieFAB from './components/AmelieFAB/AmelieFAB';
import AmelieUIDemo from './components/AmelieUIDemo/AmelieUIDemo';
import AmelieHomeFinder from './components/AmelieHomeFinder/AmelieHomeFinder';
import AmelieHomeEvaluation from './components/AmelieHomeEvaluation/AmelieHomeEvaluation';
import AmeliePSLCModal from './components/AmeliePSLCModal/AmeliePSLCModal';
import AmelieConnectForm from './components/AmelieConnectForm/AmelieConnectForm';
import AmelieSharingButtons from './components/AmelieSharingButtons/AmelieSharingButtons';
import HomeEvaluationPage from './pages/HomeEvaluation/HomeEvaluation';
import HomeFinderPage from './pages/HomeFinder/HomeFinder';

import './index.scss';

// Initialize Sentry
registerLog();

// Initialize dev utils after store is ready
import './utils/dev-utils';

/* eslint-disable */
if (!global.Intl) {
  global.Intl = require('intl');
}
/* eslint-enable */

const wrapComponent = (component, attrs) => {
  const locale = getDefaultConfig('locale') || 'en-ca';
  const messages = flattenMessages(require(`./intl/resources/${locale}.json`)); // eslint-disable-line
  const routes = Array.isArray(component)
    ? component.map((route, key) => (
      <Route
        path={route.path}
        render={() => {
          const Component = route.component;
          return <Component attrs={attrs} />;
        }}
        key={key} // eslint-disable-line
      />
    ))
    : (
      <Route
        path="*"
        render={() => {
          const Component = component;
          return <Component attrs={attrs} />;
        }}
      />
    );

  return (
    <IntlProvider locale={locale} messages={messages}>
      <Provider store={store}>
        <CookiesProvider>
          <Router>
            <Switch>
              {routes}
            </Switch>
          </Router>
        </CookiesProvider>
      </Provider>
    </IntlProvider>
  );
};

const render = (component, query) => {
  let elementName = query;
  const isClass = query.substring(0, 1) === '.';
  if (query.substring(0, 1) === '.' || query.substring(0, 1) === '#') {
    elementName = query.substring(1);
  }

  let elements = [];
  if (isClass) {
    elements = document.getElementsByClassName(elementName);
  } else {
    const element = document.getElementById(elementName);
    if (element) {
      elements.push(element);
    }
  }

  if (elements.length > 0) {
    Array.from(elements).forEach(((element) => {
      if (element) {
        const attrs = element.getAttributeNames().reduce((acc, name) => {
          return {
            ...acc, [name]: element.getAttribute(name),
          };
        }, {});

        ReactDOM.render(
          wrapComponent(component, attrs),
          element,
        );
      }
    }));
  }
};

render(AmelieConnectForm, '.amelie-connect-form');
render(AmelieSharingButtons, '.amelie-sharing-buttons');
render(AmelieMain, 'amelie-main');
render(AmelieUIDemo, 'amelie-component-gallery');
render(AmelieMenu, 'amelie-menu');
render([
  {
    path: '/free-home-evaluation',
    component: HomeEvaluationPage,
  }, {
    path: '/free-home-finder',
    component: HomeFinderPage,
  },
], 'amelie-landing');

if (getDefaultConfig('enhancedEnabled')) {
  render(AmelieSidebar, 'amelie-listing-main');
  render(AmelieExpandedDetails, 'amelie-listing-sidebar');
}

if (getDefaultConfig('favoritesEnabled')) {
  render(AmelieFavoriteListing, 'amelie-favorite');
}

if (getDefaultConfig('leadCaptureBubbleEnabled')) {
  render(AmelieFAB, 'amelie-fab');
}

if (getDefaultConfig('homeFinderForm')) {
  render(AmelieHomeFinder, 'amelie-home-finder');
}

if (getDefaultConfig('homeEvaluationForm')) {
  render(AmelieHomeEvaluation, 'amelie-home-evaluation');
}

render(AmeliePSLCModal, 'amelie-pslc');
