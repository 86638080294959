import request from 'superagent';
import { AUTHFUL_HOST } from '../constants';
import Utils from '../utils/utils';

const initialState = {
  isLoading: false,
  isSaving: false,
  isSaveSuccess: false,
  isSaveCompleted: false,
  homeTypes: [],
  formData: {
    cache: {},
  },
};

export default {
  state: initialState,
  reducers: {
    startFetchData: (state) => ({ ...state, isLoading: true }),
    failedFetchData: (state) => ({
      ...state,
      isFailed: true,
      isLoading: false,
    }),
    successFetchData: (state, payload) => ({
      ...state,
      isLoading: false,
      isFailed: false,
      ...payload,
    }),
    startSubmit: (state, payload) => ({
      ...state,
      isSaving: true,
      formData: {
        ...state.formData,
        cache: payload,
      },
    }),
    successSubmit: (state) => ({
      ...state,
      isSaving: false,
      isSaveSuccess: true,
      isSaveCompleted: true,
    }),
    failedSubmit: (state) => ({
      ...state,
      isSaving: false,
      isSaveSuccess: false,
      isSaveCompleted: true,
    }),
  },
  effects: (dispatch) => ({
    getHomeTypes() {
      return request.get(`${AUTHFUL_HOST}api/v1/home-types`);
    },
    async loadInitialFormData() {
      try {
        dispatch.homeFinder.startFetchData();

        const homeTypesResponse = await this.getHomeTypes();

        dispatch.homeFinder.successFetchData({
          homeTypes: homeTypesResponse.body.data,
        });
      } catch (error) {
        dispatch.homeFinder.failedFetchData();
      }
    },
    async saveFormData(data) {
      dispatch.homeFinder.startSubmit(data);

      const saveData = {
        ...data,
        site_id: Utils.getDefaultConfig('siteID') || 1,
      };

      try {
        const response = await request
          .post(`${AUTHFUL_HOST}api/v1/home-finder-submissions`)
          .send(saveData);

        if (!response.ok) {
          dispatch.homeFinder.failedSubmit();
        }

        dispatch.homeFinder.successSubmit();
      } catch (err) {
        dispatch.homeFinder.failedSubmit();
      }
    },
  }),
};
