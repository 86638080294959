// Dependencies.
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as authActions from '../../redux/auth';
import AmelieEnhancedFields from '../AmelieEnhancedFields/AmelieEnhancedFields';

// Define component class.
class AmelieSidebar extends PureComponent {
  // Render the component.
  render() {
    const { auth, fields, listing } = this.props;

    return (
      <>
        {auth.userToken && (
          <div>
            <AmelieEnhancedFields fields={fields} listing={listing} location="sidebar" />
          </div>
        )}
      </>
    );
  }
}

// Property validation.
AmelieSidebar.propTypes = {
  // Properties.
  auth: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  listing: PropTypes.object.isRequired,

  // Functions.
};

const mapStateToProps = ({ auth, fields, listing }) => ({
  auth,
  fields,
  listing,
});

const mapDispatchToProps = (dispatch) => {
  const actionCreators = {
    ...authActions,
  };
  return bindActionCreators(actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AmelieSidebar);

// For tests.
export { AmelieSidebar as PureAmelieSidebar };
