import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { CONNECT_FORM, getValidationMessage } from '../../utils/leads';
import AmelieDisclaimer from 'components/AmelieDisclaimer/AmelieDisclaimer';
import Utils from '../../utils/utils';

function AmelieConnectForm() {
  const dispatch = useDispatch();
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: {
      errors,
    },
  } = useForm();
  const [message, setMessage] = useState('');
  const leadCaptureRequirePhone = Utils.getDefaultConfig('leadCaptureRequirePhone');

  const onSubmit = async (values) => {
    try {
      const data = {
        name: values['your-name'],
        email: values['your-email'],
        phone_number: values['your-phone'],
        message: values['your-message'],
        source: CONNECT_FORM,
      };

      await dispatch.leadCapture.sendLead(data);
      setMessage('Thanks for reaching out!');
      reset();
    } catch (e) {
      setMessage('There was a problem sending your message, please try again later.');
    }
  };

  return (
    <div role="form" className="wpcf7 amelie-connect-form-container">
      <form className="wpcf7-form" onSubmit={handleSubmit(onSubmit)}>
        {/* this is hidden element copied from CF7 to support CSS tweaks */}
        <div style={{ display: 'none' }}>
          <input type="hidden" name="_wpcf7" value="493" />
          <input type="hidden" name="_wpcf7_version" value="5.6.4" />
          <input type="hidden" name="_wpcf7_locale" value="en_US" />
          <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f493-o1" />
          <input type="hidden" name="_wpcf7_container_post" value="0" />
          <input type="hidden" name="_wpcf7_posted_data_hash" value="" />
        </div>
        <div className="connect-field">
          <label htmlFor="your-name">Name</label>
          <span className="wpcf7-form-control-wrap" data-name="your-name">
            <input
              type="text"
              name="your-name"
              id="your-name"
              size="40"
              className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
              ref={register({ required: true })}
            />
            {errors && errors['your-name'] && <span className="wpcf7-not-valid-tip" aria-hidden="true">{getValidationMessage('name')}</span>}
          </span>
        </div>
        <div className="connect-field">
          <label htmlFor="your-email">Email</label>
          <span className="wpcf7-form-control-wrap" data-name="your-email">
            <input
              type="email"
              name="your-email"
              id="your-email"
              size="40"
              className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email stored"
              ref={register({ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i })}
            />
            {errors && errors['your-email'] && errors['your-email'].type === 'required' && <span className="wpcf7-not-valid-tip" aria-hidden="true">{getValidationMessage('email address')}</span>}
            {errors && errors['your-email'] && errors['your-email'].type === 'pattern' && <span className="wpcf7-not-valid-tip" aria-hidden="true">{getValidationMessage('email address', false)}</span>}
          </span>
        </div>
        <div className="connect-field">
          <label htmlFor="your-phone">Phone</label>
          <span className="wpcf7-form-control-wrap" data-name="your-phone">
            <Controller
              as={MaskedInput}
              control={control}
              type="tel"
              name="your-phone"
              id="your-phone"
              size="40"
              className="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel"
              mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              rules={{ required: leadCaptureRequirePhone, pattern: /^\d{3}-\d{3}-\d{4}$/ }}
              defaultValue=""
            />
            {errors && errors['your-phone'] && errors['your-phone'].type === 'required' && <span className="wpcf7-not-valid-tip" aria-hidden="true">{getValidationMessage('phone number')}</span>}
            {errors && errors['your-phone'] && errors['your-phone'].type === 'pattern' && <span className="wpcf7-not-valid-tip" aria-hidden="true">{getValidationMessage('phone number', false)}</span>}
          </span>
        </div>
        <div className="connect-field">
          <label htmlFor="your-message">Message</label>
          <span className="wpcf7-form-control-wrap" data-name="your-message">
            <textarea
              name="your-message"
              id="your-message"
              cols="40"
              rows="10"
              className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
              ref={register()}
            />
          </span>
        </div>
        <AmelieDisclaimer />
        <input value="Send" type="submit" className="wpcf7-form-control wpcf7-submit btn btn-large btn-primary" />
        {isEmpty(errors) && <div className="wpcf7-response-output" aria-hidden="true">{message}</div>}
      </form>
    </div>
  );
}

export default AmelieConnectForm;
