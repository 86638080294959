import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { Form, Input, Button, Alert } from '../AmelieUI';
import AmelieDisclaimer from 'components/AmelieDisclaimer/AmelieDisclaimer';
import Utils from '../../utils/utils';

import './AmelieLandingContactForm.scss';

// Form validation tweak. This prevents displaying validation message on the UI.
const ERROR_MESSAGE = ' ';

// antd's Form must be class component
class AmelieLandingContactForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
  };

  state = {
    hasError: false,
  };

  handleSubmit = (e) => {
    const { onSubmit, form } = this.props;
    e.preventDefault();

    form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return this.setState({
          hasError: true,
        });
      }
      if (onSubmit) {
        onSubmit(values);
      }
    });
  };

  render() {
    const { intl, form, title } = this.props;
    const { hasError } = this.state;
    const { getFieldDecorator } = form;
    const { formatMessage } = intl;
    const leadCaptureRequirePhone = Utils.getDefaultConfig('leadCaptureRequirePhone');

    return (
      <Form layout="inline" onSubmit={this.handleSubmit} className="amelie-landing-contact-form">
        <h2 className="amelie-landing-contact-form-title">
          {title}
        </h2>
        <div className="amelie-landing-contact-form-group">
          <Form.Item className="amelie-landing-contact-form-item">
            {getFieldDecorator('firstname', {
              rules: [{
                required: true,
                message: ERROR_MESSAGE,
              }],
            })(
              <Input
                placeholder={formatMessage({ id: 'amelieLanding.form.placeholderFirstName' })}
              />
            )}
          </Form.Item>
          <Form.Item className="amelie-landing-contact-form-item">
            {getFieldDecorator('lastname', {
              rules: [{
                required: true,
                message: ERROR_MESSAGE,
              }],
            })(
              <Input
                placeholder={formatMessage({ id: 'amelieLanding.form.placeholderLastName' })}
              />
            )}
          </Form.Item>
          <Form.Item className="amelie-landing-contact-form-item">
            {getFieldDecorator('email', {
              rules: [{
                required: true,
                type: 'email',
                message: ERROR_MESSAGE,
              }],
            })(
              <Input
                type="email"
                placeholder={formatMessage({ id: 'amelieLanding.form.placeholderEmailAddress' })}
              />
            )}
          </Form.Item>
          <Form.Item className="amelie-landing-contact-form-item">
            {getFieldDecorator('phoneNumber', {
              rules: [
                { required: leadCaptureRequirePhone, message: ERROR_MESSAGE },
                { pattern: /^\d{3}-\d{3}-\d{4}$/, message: ERROR_MESSAGE },
              ],
            })(
              <Input.Mask
                placeholder={leadCaptureRequirePhone ? 'Phone number' : 'Phone number (optional)'}
                mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              />
            )}
          </Form.Item>
        </div>
        {hasError && (
          <Alert
            type="error"
            className="amelie-landing-contact-form-error"
            message={intl.formatMessage({ id: 'amelieLanding.form.validationAllRequired' })}
          />
        )}
        <AmelieDisclaimer theme="dark" />
        <Button
          type="primary"
          htmlType="submit"
          className="amelie-landing-contact-form-submit"
        >
          Submit
        </Button>
      </Form>
    );
  }
}

const enhanced = compose(
  Form.create(),
  injectIntl
);

export default enhanced(AmelieLandingContactForm);
