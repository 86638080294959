const API_ENV_DEVELOPMENT = {
  NAME: 'development',
  LOL_HOST: 'http://lol.test/api/v1/',
  LOL_KEY: 'lol26955c0e2bd45be3381b512bbb77bfe6',
  AUTHFUL_HOST: 'http://authful.test/',
};

const API_ENV_STAGING = {
  NAME: 'staging',
  LOL_HOST: 'https://lmao.avenuehq.com/api/v1/',
  LOL_KEY: 'lol1304df550b96fdcec66bde8d13e90623',
  AUTHFUL_HOST: 'https://authful-staging.avenuehq.com/',
};

const API_ENV_PRODUCTION = {
  NAME: 'production',
  LOL_HOST: 'https://lol.avenuehq.com/api/v1/',
  LOL_KEY: false,
  AUTHFUL_HOST: 'https://authful.avenuehq.com/',
};

const setAPIEnv = () => {
  const { NODE_ENV, REACT_APP_API_ENV } = process.env;
  if (REACT_APP_API_ENV === 'production') {
    return API_ENV_PRODUCTION;
  } else if (REACT_APP_API_ENV === 'staging') {
    return API_ENV_STAGING;
  } else if (REACT_APP_API_ENV === 'development') {
    return API_ENV_DEVELOPMENT;
  } else if (NODE_ENV === 'production') {
    return API_ENV_PRODUCTION;
  } else {
    return API_ENV_DEVELOPMENT;
  }
};
export const API_ENV = setAPIEnv();

export const { LOL_KEY, LOL_HOST, AUTHFUL_HOST } = API_ENV;

export const getCurrentEnv = () => {
  const {
    NODE_ENV,
    REACT_APP_API_ENV,
  } = process.env;
  if (REACT_APP_API_ENV) {
    return REACT_APP_API_ENV;
  }
  return NODE_ENV;
};

export const isDevelopmentMode = () => {
  const { NODE_ENV } = process.env;
  return NODE_ENV && (NODE_ENV === 'development');
};

export const isProduction = () => !isDevelopmentMode() && getCurrentEnv() === 'production';

export const GOOGLE_API_KEY = 'AIzaSyBBHSg5XlJzHcbqn8irS6ZhpYz5fNkq47E';

const imageAssetPath = (fileName) => {
  if (process.env.NODE_ENV === 'development' || window.location.hostname === 'localhost') { // dev env
    return `amelie/static/images/${fileName}`;
  }
  return `//${window.location.host}/amelie/static/images/${fileName}`;
};

export const LANDING_PAGE_THEMES = {
  kitchen: imageAssetPath('kitchen.jpg'),
  neighbourhood: imageAssetPath('neighbourhood.jpg'),
  laptop: imageAssetPath('laptop.jpg'),
  satellite: imageAssetPath('satellite.jpg'),
};

export const SENTRY_DSN = 'https://7c7304c4530f4c969d5925e1506a7bc2@o195130.ingest.sentry.io/1877694';
