import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import { Icon } from '..';
import styles from './Input.module.scss';

const Input = forwardRef((props, ref) => {
  const { className, icon, suffix, type, value } = props;
  const hasSuffix = !isEmpty(suffix) && !isEmpty(value);
  const inputType = type || 'text';

  return (
    <>
      {icon && <Icon type={icon} className={styles.icon} />}
      {hasSuffix && <span className={styles.suffix}>{suffix}</span>}

      <input
        ref={ref}
        type={inputType}
        {...omit(props, [className, icon, suffix])}
        className={classNames(styles.input, className, {
          [styles.hasIcon]: !!icon,
          [styles.hasSuffix]: hasSuffix,
        })}
      />
    </>
  );
});
Input.displayName = 'Input';

const InputMask = forwardRef((props, ref) => {
  const { className } = props;

  return (
    <MaskedInput
      {...omit(props, [className])}
      ref={ref}
      className={classNames(styles.input, className)}
    />
  );
});
InputMask.displayName = 'InputMask';

const TextArea = forwardRef((props, ref) => {
  const { className } = props;

  return (
    <textarea
      {...omit(props, [className])}
      ref={ref}
      className={classNames(styles.textarea, className)}
    />
  );
});
TextArea.displayName = 'TextArea';

Input.propTypes = {
  className: PropTypes.string,
};

Input.defaultProps = {
  className: '',
};

Input.TextArea = TextArea;
Input.Mask = InputMask;

export default Input;
