import { init } from '@rematch/core';
import thunk from 'redux-thunk';

import leadCapture from './models/leadCapture';
import homeFinder from './models/homeFinder';
import evaluationForm from './models/evaluationForm';
import landingPages from './models/landingPages';

import { reducer as auth } from './redux/auth';
import { reducer as fields } from './redux/fields';
import { reducer as listing } from './redux/listing';
import { reducer as favorites } from './redux/favorites';

const store = init({
  models: {
    leadCapture,
    homeFinder,
    evaluationForm,
    landingPages,
  },
  redux: {
    reducers: {
      auth,
      fields,
      listing,
      favorites,
    },
    middlewares: [thunk],
  },
});

export const { getState, dispatch } = store;
export default store;
