import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import queryString from 'query-string';
import { Icon } from '../AmelieUI';
import Utils from '../../utils/utils';
import AmeliePSLCForm from '../AmeliePSLCForm/AmeliePSLCForm';
import AmeliePSLCSocialLogin from '../AmeliePSLCSocialLogin/AmeliePSLCSocialLogin';
import AmelieDisclaimer from 'components/AmelieDisclaimer/AmelieDisclaimer';
import AmelieUserToolbar from '../AmelieUserToolbar/AmelieUserToolbar';
import PropTypes from 'prop-types';
import { signIn, signOut } from '../../redux/auth';

import './AmeliePSLCModal.scss';

const PLC_HIDDEN = 0;
const PLC_SHOW_FORM = 1;
const PLC_SHOW_CONFIRMATION = 2;
const PLC_SHOW_ERROR = 3;

const PLC_COOKIE = 'predefinedSearchModalDismissed';
const PLC_COOKIE_EXPIRY = 7; // Expiry in days after PLC is submitted

const AmeliePSLCModal = () => {
  const [cookies, setCookie] = useCookies();
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const sourceUrl = cookies.fromPredefinedSearch ? decodeURIComponent(cookies.fromPredefinedSearch) : null;

  const [visibleState, setVisibleState] = useState(PLC_HIDDEN);
  const [loading, setLoading] = useState(false);
  const [isSocialLogin, setIsSocialLogin] = useState(false);

  const promptedLeadCaptureDismissible = Boolean(Utils.getDefaultConfig('promptedLeadCaptureDismissible'));
  const promptedLeadCaptureEnabled = Utils.getDefaultConfig('promptedLeadCaptureEnabled');
  const promptedLeadCaptureDelay = Utils.getDefaultConfig('promptedLeadCaptureDelay');
  const promptedLeadCapturePages = Utils.getDefaultConfig('promptedLeadCapturePages');

  const formatProviderName = (provider) => {
    if (!provider) return '';
    return provider === 'facebook' ? 'Facebook' : 
           provider === 'google' ? 'Google' : 
           provider.charAt(0).toUpperCase() + provider.slice(1);
  };

  const decodeJWT = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      return JSON.parse(window.atob(base64));
    } catch (e) {
      console.error('Error decoding JWT:', e);
      return null;
    }
  };

  const getSocialLoginParams = () => {
    if (window.location.hash === '#_=_') {
      window.history.replaceState(null, '', window.location.href.slice(0, -4));
    }

    const parsed = queryString.parse(window.location.search);
    if (parsed.actionFrom === 'pslc' && parsed.token) {
      try {
        // Get user data from JWT payload
        const payload = decodeJWT(parsed.token);
        console.log('JWT payload:', payload);

        if (!payload) {
          throw new Error('Invalid JWT payload');
        }

        // Use userData from JWT payload (backend should include these)
        const userName = payload.name || payload.userName || 
                        (payload.provider ? `${formatProviderName(payload.provider)} User` : 'Guest User');
        
        // Store email if present in JWT
        if (payload.email) {
          window.localStorage.setItem('userEmail', payload.email);
        }
        
        return {
          token: parsed.token,
          userName,
          email: payload.email,
          provider: payload.provider,
          sub: payload.sub,
        };
      } catch (e) {
        console.error('Error parsing social login data:', e);
        return {
          token: parsed.token,
          userName: 'Guest User',
        };
      }
    }
    return null;
  };

  const isPredefinedSearch = (function () {
    const referrerUrl = decodeURIComponent(document.referrer);
    if (sourceUrl && sourceUrl === referrerUrl) {
      return true;
    }
    if ((referrerUrl.replace('?return_to=carmen', '') === window.location.href)) {
      return true;
    }
    return false;
  })();

  // Always log this info
  console.log('referrerUrl', decodeURIComponent(document.referrer));
  console.log('isPredefinedSearch', isPredefinedSearch);

  useEffect(() => {
    if (window._debugPSLCState !== undefined) {
      setVisibleState(Number(window._debugPSLCState));
    }

    (async () => {
      const loginParams = getSocialLoginParams();
      if (loginParams) {
        try {
          console.log('Social login params:', loginParams);

          // Dispatch login first to ensure toolbar is ready
          if (loginParams.userName && loginParams.userName !== 'Guest User') {
            await dispatch(signIn(loginParams.token, loginParams.userName));
          } else {
            await dispatch(signIn(loginParams.token));
          }

          const data = {
            token: loginParams.token,
            listing_url: `${window.location.origin}${window.location.pathname}`,
            predefined_search_url: sourceUrl,
          };

          await dispatch.leadCapture.sendPLCLead({ sourceUrl, isPredefinedSearch, data });
          setDismissedCookie(PLC_COOKIE_EXPIRY);
          setIsSocialLogin(true);
          setVisibleState(PLC_SHOW_CONFIRMATION);

          // Keep any existing query params that aren't related to the social login
          const currentParams = queryString.parse(window.location.search);
          delete currentParams.actionFrom;
          delete currentParams.token;
          delete currentParams.lead_source_id;
          delete currentParams.name;  // Clean up sensitive data
          delete currentParams.email; // Clean up sensitive data
          
          const newSearch = Object.keys(currentParams).length > 0 
            ? `?${queryString.stringify(currentParams)}`
            : '';
            
          history.replace(`${window.location.pathname}${newSearch}`);
        } catch (e) {
          console.error('Social login error:', e);
          setVisibleState(PLC_SHOW_ERROR);
        }
        return;
      }

      setTimeout(() => {
        if (!promptedLeadCaptureEnabled) {
          return;
        }

        if (cookies[PLC_COOKIE]) {
          return;
        }

        if (!isPredefinedSearch && promptedLeadCapturePages === 'search') {
          return;
        }

        if (visibleState !== PLC_HIDDEN) {
          return;
        }

        setVisibleState(PLC_SHOW_FORM);
      }, Number(promptedLeadCaptureDelay) * 1000);
    })();
  }, [history, dispatch]);

  const handleSignOut = () => {
    // Remove the dismiss cookie to re-trigger PSLC on next login
    setCookie(PLC_COOKIE, '', { path: '/', expires: new Date(0) });
    dispatch(signOut());
    // Show PSLC form after a brief delay to allow signout to complete
    setTimeout(() => setVisibleState(PLC_SHOW_FORM), 100);
  };

  /**
   * Set cookie to dismiss the modal for a number of days.
   * Default is 1 day.
   *
   * @param {number} days
   */
  const setDismissedCookie = (days = 1) => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + days);
    setCookie(PLC_COOKIE, true, { path: '/', expires: expiryDate });
  };

  const handleCloseClick = () => {
    setDismissedCookie();
    setVisibleState(PLC_HIDDEN);
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const data = {
        ...values,
        listing_url: `${window.location.origin}${window.location.pathname}`,
        predefined_search_url: sourceUrl,
      };

      // Store email for future form pre-fills
      if (values.email) {
        window.localStorage.setItem('userEmail', values.email);
      }

      await dispatch.leadCapture.sendPLCLead({ sourceUrl, isPredefinedSearch, data });
      setDismissedCookie(PLC_COOKIE_EXPIRY);
      setLoading(false);
      setVisibleState(PLC_SHOW_CONFIRMATION);
    } catch (e) {
      setLoading(false);
      setVisibleState(PLC_SHOW_ERROR);
    }
  };

  function ConfirmationContent({ onClose, isSocialLogin }) {
    const handleWrapperClick = (e) => {
      // Only close if clicking the wrapper itself, not its children
      if (e.target === e.currentTarget) {
        onClose();
      }
    };

    return (
      <div className="amelie-pslc-wrapper" onClick={handleWrapperClick} role="button" tabIndex={0}>
        <div className="amelie-pslc-confirmation">
          <Icon type="close" className="amelie-pslc-confirmation-close" onClick={onClose} />
          <h2 className="title">Thank you for your interest!</h2>
          {isSocialLogin && (
            <div className="success-banner">
              <Icon type="check" className="icon" />
              <p>Your social login was successful and we&#39;ve received your contact details!</p>
            </div>
          )}
          <p>We&apos;ll be in touch shortly. In the meantime, feel free to close this message to view the full property details and explore more listings.</p>
        </div>
      </div>
    );
  }

  ConfirmationContent.propTypes = {
    onClose: PropTypes.func.isRequired,
    isSocialLogin: PropTypes.bool.isRequired,
  };

  function ErrorContent({ onClose, error }) {
    // Always log errors
    if (error) {
      console.error('PSLC Error:', error);
    }
    
    return (
      <div className="amelie-pslc-wrapper">
        <div className="amelie-pslc-confirmation">
          <Icon type="close" className="amelie-pslc-confirmation-close" onClick={onClose} />
          <h2 className="title">Sorry</h2>
          <p>We were unable to save your information at this time, please try again later.</p>
          {error && (
            <p className="error-details">{error.message}</p>
          )}
        </div>
      </div>
    );
  }

  ErrorContent.propTypes = {
    onClose: PropTypes.func.isRequired,
    error: PropTypes.object,
  };

  function FormContent({ onClose, promptedLeadCaptureDismissible, onSubmit, isSaving, isPredefinedSearch }) {
    return (
      <div className="amelie-pslc-wrapper">
        <div className={classNames('amelie-pslc', { dismissible: promptedLeadCaptureDismissible })}>
          { promptedLeadCaptureDismissible && (
            <Icon type="close" className="amelie-pslc-close" onClick={onClose} />
          ) }
          <div className="amelie-pslc-body">
            <h2 className="title">Ready to learn more about this listing?</h2>
            <p className="subtitle">Please enter your info below to connect.</p>
            <AmeliePSLCForm onSubmit={onSubmit} isSaving={isSaving} />
            <AmeliePSLCSocialLogin isPredefinedSearch={isPredefinedSearch} />
            <AmelieDisclaimer />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="amelie-pslc">
      {auth.userToken && (
        <AmelieUserToolbar auth={auth} signOut={handleSignOut} />
      )}
      {visibleState !== PLC_HIDDEN && (
        <div className="amelie-pslc-wrapper">
          {visibleState === PLC_SHOW_CONFIRMATION && (
            <ConfirmationContent onClose={handleCloseClick} isSocialLogin={isSocialLogin} />
          )}
          {visibleState === PLC_SHOW_ERROR && (
            <ErrorContent onClose={handleCloseClick} />
          )}
          {visibleState === PLC_SHOW_FORM && (
            <FormContent
              onClose={handleCloseClick}
              promptedLeadCaptureDismissible={promptedLeadCaptureDismissible}
              onSubmit={handleFormSubmit}
              isSaving={loading}
              isPredefinedSearch={isPredefinedSearch}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default AmeliePSLCModal;
