import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { Button } from '../AmelieUI';
import { AUTHFUL_HOST } from '../../constants';
import Utils from '../../utils/utils';
import { getLeadSourceIdByName } from '../../utils/leads';
import queryString from 'query-string';

import './AmeliePSLCSocialLogin.scss';

class AmeliePSLCSocialLogin extends Component {
  handleClick = (app) => {
    const { isPredefinedSearch } = this.props;
    const siteID = Utils.getDefaultConfig('siteID') || 1;

    const leadSourceId = isPredefinedSearch
      ? getLeadSourceIdByName('PREDEFINED_SEARCH_LEAD_CAPTURE')
      : getLeadSourceIdByName('PROMPTED_LEAD_CAPTURE')

    // Keep any existing name and email in the URL
    const currentParams = queryString.parse(window.location.search);
    const newURL = new URL(window.location.href);
    newURL.searchParams.set('actionFrom', 'pslc');
    newURL.searchParams.set('lead_source_id', leadSourceId);
    if (currentParams.name) {
      newURL.searchParams.set('name', currentParams.name);
    }
    if (currentParams.email) {
      newURL.searchParams.set('email', currentParams.email);
    }
    const redirectUrl = newURL.toString();

    const url = `${AUTHFUL_HOST}${app}/login?siteID=${siteID}&lead_source_id=${leadSourceId}&redirect_url=${encodeURIComponent(
      redirectUrl
    )}`;
    window.location = url;
  };

  render() {
    return (
      <div className="amelie-pslc-social-login">
        <h4 className="amelie-pslc-social-login-title">or connect with</h4>
        <div className="amelie-pslc-social-login-buttons">
          <Button
            icon="facebook"
            onClick={() => this.handleClick('facebook')}
            className="amelie-pslc-social-login-button facebook"
          >
            Facebook
          </Button>
          <Button
            icon="google"
            onClick={() => this.handleClick('google')}
            className="amelie-pslc-social-login-button google"
          >
            Google
          </Button>
        </div>
      </div>
    );
  }
}

AmeliePSLCSocialLogin.propTypes = {
  intl: PropTypes.object.isRequired,
  isPredefinedSearch: PropTypes.bool.isRequired,
};

AmeliePSLCSocialLogin.defaultProps = {};

const enhanced = compose(injectIntl);

export default enhanced(AmeliePSLCSocialLogin);
