// listing.js

/**
 * Dependencies.
 */

import request from 'superagent';
import lol from '../api/lol';
import Utils from '../utils/utils';

/**
 * Actions.
 */

export const LOAD_LISTING = 'rb-amelie/listing/LOAD_LISTING';
export const LOAD_LISTING_ROOMS = 'rb-amelie/listing/LOAD_LISTING_ROOMS';

/**
 * Local variables.
 */

const maxAttempts = 3;
let listingFailures = 0;

/**
 * Reducer.
 */

const initialState = {};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_LISTING: {
      return action.listing;
    }

    case LOAD_LISTING_ROOMS: {
      return {
        ...state,
        rooms: action.rooms,
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Action creators.
 */

export function loadListing() {
  return (dispatch, getState) => {
    if (listingFailures < maxAttempts) {
      const state = getState();
      const { auth } = state;

      if (auth.userToken !== null) {
        const lolURL = lol.buildListingURL(auth.userToken);

        // Call to LOL API to retrieve listing details.
        return request.get(lolURL)
          .timeout({
            response: 10000,
            deadline: 15000,
          })
          .then((json) => {
            const { body } = json;
            const listing = body.bundle[0];
            dispatch({
              type: LOAD_LISTING,
              listing,
            });
            // Success! Clear the failure count.
            listingFailures = 0;
            dispatch(loadListingRooms());
          })
          .catch((error) => {
            listingFailures += 1;
            Utils.debug(error, listingFailures);
            // Goonies never say die!
            dispatch(loadListing());
          });
      }
    } else {
      // Too many failures. Log and reset.
      Utils.debug(`Call to LOL API failed after ${maxAttempts} attempts.`);
      listingFailures = 0;
    }
  };
}

export function loadListingRooms() {
  return (dispatch, getState) => {
    if (listingFailures < maxAttempts) {
      const state = getState();
      const { auth, listing } = state;

      if (auth.userToken !== null) {
        const lolURL = lol.buildListingRoomsURL(listing.id, auth.userToken);

        // Call to LOL API to retrieve listing details.
        return request.get(lolURL)
          .timeout({
            response: 10000,
            deadline: 15000,
          })
          .then((json) => {
            const { body } = json;
            const rooms = body.bundle;
            dispatch({
              type: LOAD_LISTING_ROOMS,
              rooms,
            });
            // Success! Clear the failure count.
            listingFailures = 0;
          })
          .catch((error) => {
            listingFailures += 1;
            Utils.debug(error, listingFailures);
            // Goonies never say die!
            dispatch(loadListingRooms());
          });
      }
    } else {
      // Too many failures. Log and reset.
      Utils.debug(`Call to LOL API failed after ${maxAttempts} attempts.`);
      listingFailures = 0;
    }
  };
}
