import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import AmelieLandingContainer from '../../components/AmelieLandingContainer/AmelieLandingContainer';
import AmelieLandingFinderForm from '../../components/AmelieLandingFinderForm/AmelieLandingFinderForm';
import AmelieLandingConfirmation from '../../components/AmelieLandingConfirmation/AmelieLandingConfirmation';

import Utils from '../../utils/utils';

class HomeFinder extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    isFormSubmitted: false,
    isFormError: false,
  };

  handleFinderFormSubmit = async (data) => {
    const { dispatch } = this.props;

    const success = await dispatch.landingPages.postHomeFinderForm(data);
    if (success) {
      Utils.sendToGoogle('homeFinder');
    }

    this.setState({
      isFormSubmitted: true,
      isFormError: !success,
    });
  };

  render() {
    const { intl } = this.props;
    const { isFormSubmitted, isFormError } = this.state;
    const formTitle = Utils.getDefaultConfig('homeFinderLandingPageHeader') || intl.formatMessage({ id: 'amelieLanding.homeFinder.header' });
    const confirmationMessage = Utils.getDefaultConfig('homeFinderLandingPageConfirmation') || intl.formatMessage({ id: 'amelieLanding.form.confirmation' });
    const errorMessage = intl.formatMessage({ id: 'amelieLanding.form.confirmationError' });
    const showBackButton = Utils.getDefaultConfig('isSiteLive') || false;

    return (
      <AmelieLandingContainer
        containerType="homeFinder"
        title={intl.formatMessage({ id: 'amelieLanding.homeFinder.title' })}
      >
        {!isFormSubmitted && (
          <AmelieLandingFinderForm
            title={formTitle}
            onSubmit={this.handleFinderFormSubmit}
          />
        )}
        {isFormSubmitted && (
          <AmelieLandingConfirmation
            title={!isFormError ? confirmationMessage : errorMessage}
            showBackButton={showBackButton}
          />
        )}
      </AmelieLandingContainer>
    );
  }
}

const enhanced = compose(
  connect(),
  injectIntl,
);

export default enhanced(HomeFinder);
