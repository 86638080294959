import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Utils from '../../utils/utils';

import './AmelieDisclaimer.scss';

const AmelieDisclaimer = (props) => {
  const { theme } = props;

  const clientName = Utils.getDefaultConfig('clientName');
  const termsAndConditions = Utils.getDefaultConfig('termsAndConditions');
  const privacyPolicy = Utils.getDefaultConfig('privacyPolicy');

  const policyLinks = [];
  if (termsAndConditions) {
    policyLinks.push(<a href={termsAndConditions} target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>);
  }
  if (privacyPolicy) {
    policyLinks.push(<a href={privacyPolicy} target="_blank" rel="noopener noreferrer">Privacy Policy</a>);
  }

  const policyLinksJSX = policyLinks.map((link, index) => (
    <>
      {link}
      {index !== policyLinks.length - 1 && ' and '}
    </>
  ));

  return (
    <div
      className={classNames("amelie-disclaimer", {
        "theme--dark": theme === 'dark',
        "theme--light": theme !== 'dark',
      })}
    >
      I agree to be contacted {clientName ? `by ${clientName}` : ''} via call, email, and text.
      To opt-out, reply STOP at any time or click unsubscribe in the emails.
      Message and data rates may apply. Information is used in accordance with our {policyLinksJSX}.
    </div>
  );
}

AmelieDisclaimer.propTypes = {
  theme: PropTypes.string,
};

AmelieDisclaimer.defaultProps = {
  theme: 'light',
};

export default injectIntl(AmelieDisclaimer);
