import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Form, Input, Checkbox, Button, Select } from '../AmelieUI';
import Utils from '../../utils/utils';
import { getValidationMessage } from '../../utils/leads';
import AmelieDisclaimer from 'components/AmelieDisclaimer/AmelieDisclaimer';

import './AmelieHomeFinderForm.scss';

const FormItem = Form.Item;

class AmelieHomeFinderForm extends Component {
  componentDidMount() {
    const { loadInitialFormData } = this.props;
    loadInitialFormData();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form, saveFormData } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        saveFormData(values);
      }
    });
  };

  render() {
    const {
      form,
      intl,
      homeTypes,
      isLoading,
      isSaving,
      isSaveCompleted,
      isSaveSuccess,
    } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const { formatMessage } = intl;

    const homeOtherValue = getFieldValue('home_types') && getFieldValue('home_types').find((e) => e === '100');
    const leadCaptureRequirePhone = Utils.getDefaultConfig('leadCaptureRequirePhone');

    return (
      <Form className="amelie-home-finder-form" onSubmit={this.handleSubmit}>
        {isSaveCompleted && !isSaveSuccess && (
          <div className="amelie-home-finder-form-message">
            {intl.formatMessage({ id: 'amelieHomeFinder.form.alert.error' })}
          </div>
        )}
        <div>
          <FormItem className="amelie-home-finder-form-item">
            {getFieldDecorator('description', {
              rules: [{
                required: true,
                message: formatMessage({ id: 'amelieHomeFinder.form.descriptionValidation' }),
              }],
            })(
              <Input.TextArea className="amelie-home-finder-description" placeholder={formatMessage({ id: 'amelieHomeFinder.form.description' })} />
            )}
          </FormItem>
          <FormItem className="amelie-home-finder-form-item">
            {getFieldDecorator('areas_communities', {
              rules: [{
                required: true,
                message: formatMessage({ id: 'amelieHomeFinder.form.areasValidation' }),
              }],
            })(
              <Input placeholder={formatMessage({ id: 'amelieHomeFinder.form.areasCommunities' })} />
            )}
          </FormItem>
        </div>
        <div className="amelie-home-finder-section">
          <h3>
            {formatMessage({ id: 'amelieHomeFinder.form.details.title' })}
          </h3>
          <FormItem className="amelie-home-finder-form-item amelie-home-finder-prop-type">
            {getFieldDecorator('home_types')(
              <Checkbox.Group className="amelie-home-finder-check-group">
                {homeTypes.map((type) => (
                  <Checkbox key={type.id} value={type.id}>
                    {type.type}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            )}
            {homeOtherValue === '100' && getFieldDecorator('home_type_other')(
              <Input className="amelie-eval-other-input" placeholder={formatMessage({ id: 'amelieHomeEvaluation.form.property.otherInput' })} />
            )}
          </FormItem>
          <div className="column-50 column-50-mob">
            <FormItem
              className="amelie-home-finder-form-item">
              {getFieldDecorator('price')(
                <Select
                  placeholder="Price range"
                  options={[
                    { value: '2500000', label: '$2,500,000+' },
                    { value: '2000000-2500000', label: '$2,000,000 - $2,500,000' },
                    { value: '1500000-2000000', label: '$1,500,000 - $2,000,000' },
                    { value: '1000000-1500000', label: '$1,000,000 - $1,500,000' },
                    { value: '750000-1000000', label: '$750,000 - $1,000,000' },
                    { value: '500000-750000', label: '$500,000 - $750,000' },
                    { value: '350000-500000', label: '$350,000 - $500,000' },
                    { value: '100000-350000', label: '$100,000 - $350,000' },
                  ]}
                />
              )}
            </FormItem>
            <FormItem className="amelie-home-finder-form-item">
              {getFieldDecorator('min_square_footage')(
                <Input
                  type="number"
                  suffix={formatMessage({ id: 'amelieHomeFinder.form.details.sqft' })}
                  placeholder={formatMessage({ id: 'amelieHomeFinder.form.details.minSqft' })}
                />
              )}
            </FormItem>
          </div>
          <div className="column-50 column-50-mob">
            <FormItem
              className="amelie-home-finder-form-item">
              {getFieldDecorator('minBeds')(
                <Select
                  placeholder="Minimum bedrooms"
                  options={[
                    { value: '0', label: 'Any' },
                    { value: '1', label: '1+' },
                    { value: '2', label: '2+' },
                    { value: '3', label: '3+' },
                    { value: '4', label: '4+' },
                  ]}
                />
              )}
            </FormItem>
            <FormItem
              className="amelie-home-finder-form-item">
              {getFieldDecorator('minBaths')(
                <Select
                  placeholder="Minimum bathrooms"
                  options={[
                    { value: '0', label: 'Any' },
                    { value: '1', label: '1+' },
                    { value: '2', label: '2+' },
                    { value: '3', label: '3+' },
                    { value: '4', label: '4+' },
                  ]}
                />
              )}
            </FormItem>
          </div>
        </div>
        <div className="amelie-home-finder-section">
          <h3>
            {formatMessage({ id: 'amelieHomeFinder.form.contact.title' })}
          </h3>
          <div className="column-50">
            <FormItem className="amelie-home-finder-form-item">
              {getFieldDecorator('first_name', {
                rules: [{
                  required: true,
                  message: getValidationMessage(formatMessage({ id: 'amelieHomeFinder.form.contact.firstName' })),
                }],
              })(
                <Input placeholder={formatMessage({ id: 'amelieHomeFinder.form.contact.firstName' })} />
              )}
            </FormItem>
            <FormItem className="amelie-home-finder-form-item">
              {getFieldDecorator('last_name', {
                rules: [{
                  required: true,
                  message: getValidationMessage(formatMessage({ id: 'amelieHomeFinder.form.contact.lastName' })),
                }],
              })(
                <Input placeholder={formatMessage({ id: 'amelieHomeFinder.form.contact.lastName' })} />
              )}
            </FormItem>
          </div>
          <div className="column-50">
            <FormItem className="amelie-home-finder-form-item">
              {getFieldDecorator('email', {
                rules: [{
                  required: true,
                  type: 'email',
                  message: getValidationMessage('email address', false),
                }],
              })(
                <Input placeholder={formatMessage({ id: 'amelieHomeFinder.form.contact.email' })} />
              )}
            </FormItem>
            <FormItem className="amelie-home-finder-form-item">
              {getFieldDecorator('phone_number', {
                rules: [
                  {
                    required: leadCaptureRequirePhone,
                    message: getValidationMessage('phone number'),
                  },
                  {
                    pattern: /^\d{3}-\d{3}-\d{4}$/,
                    message: getValidationMessage('phone number', false),
                  },
                ],
              })(
                <Input.Mask
                  placeholder={formatMessage({ id: 'amelieHomeFinder.form.contact.phone' })}
                  mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                />
              )}
            </FormItem>
          </div>
        </div>
        <AmelieDisclaimer />
        <Button
          className="amelie-home-finder-submit-btn"
          htmlType="submit"
          type="primary"
          disabled={isSaving || isLoading}
          loading={isSaving}
        >
          {formatMessage({ id: 'amelieHomeFinder.form.submit' })}
        </Button>
      </Form>
    );
  }
}

AmelieHomeFinderForm.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadInitialFormData: PropTypes.func.isRequired,
  saveFormData: PropTypes.func.isRequired,
  isSaveCompleted: PropTypes.bool.isRequired,
  isSaveSuccess: PropTypes.bool.isRequired,
  homeTypes: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => state.homeFinder;
const mapDispatchtoProps = (actions) => actions.homeFinder;

const enhanced = compose(
  Form.create(),
  connect(mapStatetoProps, mapDispatchtoProps),
  injectIntl
);

export default enhanced(AmelieHomeFinderForm);
