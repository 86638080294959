import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Utils from '../../utils/utils';

function AmelieSignInPrompt({ auth, showForm, intl }) {
  if (auth.userToken === null) {
    let message = '';

    if (Utils.getDefaultConfig('favoritesEnabled') && Utils.getDefaultConfig('enhancedEnabled')) {
      message = intl.formatMessage({ id: 'amelieSignInPrompt.message.favoritesEnhancedEnabled' });
    } else if (!Utils.getDefaultConfig('favoritesEnabled') && Utils.getDefaultConfig('enhancedEnabled')) {
      message = intl.formatMessage({ id: 'amelieSignInPrompt.message.enhancedEnabled' });
    } else {
      message = intl.formatMessage({ id: 'amelieSignInPrompt.message.favoritesEnabled' });
    }

    return (
      <div>
        {message}
        &nbsp;
        <strong
          onClick={() => showForm(false)}
          onKeyDown={() => showForm(false)}
        >
          {intl.formatMessage({ id: 'amelieSignInPrompt.signIn' })}
        </strong>
        .
      </div>
    );
  }
  return null;
}

AmelieSignInPrompt.propTypes = {
  // Properties.
  auth: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,

  // Functions.
  showForm: PropTypes.func.isRequired,
};

export default injectIntl(AmelieSignInPrompt);
