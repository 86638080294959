import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import AmelieLandingContainer from '../../components/AmelieLandingContainer/AmelieLandingContainer';
import AmelieLandingAddressForm from '../../components/AmelieLandingAddressForm/AmelieLandingAddressForm';
import AmelieLandingContactForm from '../../components/AmelieLandingContactForm/AmelieLandingContactForm';
import AmelieLandingConfirmation from '../../components/AmelieLandingConfirmation/AmelieLandingConfirmation';

import Utils from '../../utils/utils';

class HomeEvaluation extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    showAddressForm: true,
    showContactForm: false,
    isFormSubmitted: false,
    isFormError: false,
    addressForm: null,
  };

  handleAddressFormSubmit = (values) => {
    this.setState({
      showAddressForm: false,
      showContactForm: true,
      addressForm: values,
    });
  };

  handleContactFormSubmit = async (values) => {
    const { dispatch } = this.props;
    const { addressForm } = this.state;

    const data = { ...addressForm, ...values };
    const success = await dispatch.landingPages.postHomeEvaluationForm(data);
    if (success) {
      Utils.sendToGoogle('homeEvaluation');
    }

    this.setState({
      showAddressForm: false,
      showContactForm: false,
      isFormSubmitted: true,
      isFormError: !success,
    });
  };

  render() {
    const { intl } = this.props;
    const { showAddressForm, showContactForm, isFormSubmitted, isFormError } = this.state;
    const formTitle = Utils.getDefaultConfig('homeEvaluationLandingPageHeader') || intl.formatMessage({ id: 'amelieLanding.homeEvaluation.header' });
    const confirmationMessage = Utils.getDefaultConfig('homeEvaluationLandingPageConfirmation') || intl.formatMessage({ id: 'amelieLanding.form.confirmation' });
    const errorMessage = intl.formatMessage({ id: 'amelieLanding.form.confirmationError' });
    const showBackButton = Utils.getDefaultConfig('isSiteLive') || false;

    return (
      <AmelieLandingContainer
        containerType="homeEvaluation"
        title={intl.formatMessage({ id: 'amelieLanding.homeEvaluation.title' })}
      >
        {showAddressForm && (
          <AmelieLandingAddressForm
            title={formTitle}
            onSubmit={this.handleAddressFormSubmit}
          />
        )}
        {showContactForm && (
          <AmelieLandingContactForm
            title={formTitle}
            onSubmit={this.handleContactFormSubmit}
          />
        )}
        {isFormSubmitted && (
          <AmelieLandingConfirmation
            title={!isFormError ? confirmationMessage : errorMessage}
            showBackButton={showBackButton}
          />
        )}
      </AmelieLandingContainer>
    );
  }
}

const enhanced = compose(
  connect(),
  injectIntl,
);

export default enhanced(HomeEvaluation);
