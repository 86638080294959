import {
  init, captureMessage, captureException, configureScope, withScope,
} from '@sentry/browser';
import { API_ENV, SENTRY_DSN } from '../constants';

export const LOG_EVENTS = {
  API_RESPONSE: 'API_RESPONSE',
  AUTH_FAIL: 'AUTH_FAIL',
  NOT_FOUND: 'NOT_FOUND',
  SEND_TO_GOOGLE: 'SEND_TO_GOOGLE',
};

/**
 * Call this before mount the react app
 * * If this function is not registered, the sentry system will not log the messages
 */
export const registerLog = () => {
  return init({
    dsn: SENTRY_DSN,
    environment: API_ENV.NAME,
    ignoreErrors: [
      'Object Not Found Matching Id',
    ],
  });
};

/**
 * Pass the current signed user context
 * @param {object} user
 */
export const setActiveUser = (user) => {
  configureScope((scope) => {
    scope.setUser(user);
  });
};

/**
 * Use only for exceptions
 * @param {string} error
 * @param {object} errorInfo
 */
export const logException = (error, errorInfo) => {
  configureScope((scope) => {
    scope.setExtras(errorInfo);
  });
  captureException(error);
};

/**
 * Use this helper to log non-exceptions events
 * @param {object} params {
 * eventId: {string} LOG_EVENTS[],
 * message: {string},
 * level: {string} LOG_EVENTS[],
 * extra: {array} [{ key, text }],
 * }
 */
export const logEvent = ({
  eventId, message, level = 'info', extra = [],
}) => withScope((scope) => {
  scope.setLevel(level);
  if (extra.length) {
    scope.setExtras(extra);
  }
  return captureMessage(`${eventId} - ${message}`);
});
