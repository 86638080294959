import React, { forwardRef, useContext, createContext } from 'react';
import styles from './Checkbox.module.scss';

const CheckboxContext = createContext({});

const Checkbox = forwardRef(({
  children,
  value,
  onChange,
  name,
  defaultChecked,
  disabled,
}, ref) => {
  const group = useContext(CheckboxContext);

  // maybe i'm reinventing the wheel
  const handleChange = (e) => {
    if (group.onChange) {
      if (group.value && group.value.includes(String(value))) {
        return group.onChange(group.value.filter((item) => item !== String(value)));
      } else {
        const groupValue = group.value || [];
        return group.onChange([...groupValue, e.target.value]);
      }
    }

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <label className={styles.checkbox}>
      <input
        ref={ref}
        type="checkbox"
        value={value}
        name={name}
        defaultChecked={defaultChecked}
        disabled={disabled || group.disabled}
        checked={group.value !== undefined ? group.value.includes(String(value)) : undefined}
        onChange={handleChange}
      />
      <span>{children}</span>
    </label>
  );
});
Checkbox.displayName = 'Checkbox';

const CheckboxGroup = forwardRef(({ children, className, ...rest }, ref) => {
  return (
    <div className={className}>
      <CheckboxContext.Provider ref={ref} value={rest}>{children}</CheckboxContext.Provider>
    </div>
  );
});
CheckboxGroup.displayName = 'CheckboxGroup';

Checkbox.Group = CheckboxGroup;

export default Checkbox;
