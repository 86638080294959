import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import has from 'lodash/has';
import { AddressSearchInput, Input, Form, Select, Radio, Button, Icon } from '../AmelieUI';
import Utils from '../../utils/utils';
import { getValidationMessage } from '../../utils/leads';
import AmelieDisclaimer from 'components/AmelieDisclaimer/AmelieDisclaimer';

import './AmelieHomeEvaluationForm.scss';

const FormItem = Form.Item;

class AmelieHomeEvaluationForm extends Component {
  state = {
    isPropertyDetailsOpen: false,
    isAddressSectionOpen: false,
    isInvalidAddressSearch: false,
  };

  componentDidMount() {
    const { loadInitialFormData } = this.props;

    loadInitialFormData();
  }

  componentDidUpdate(prevProps) {
    const { isSaveSuccess: prevIsSaveSuccess } = prevProps;
    const { isSaveSuccess, form } = this.props;
    // Reset the form once it has been submitted successfully`
    if (!prevIsSaveSuccess && isSaveSuccess) {
      form.resetFields();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { isAddressSectionOpen } = this.state;
    const { form, saveFormData } = this.props;

    form.validateFieldsAndScroll((err, values) => {
      // If the current search value does not returns any valid results, show an error message
      const isInvalidAddressSearch = Boolean(!isAddressSectionOpen && form.getFieldValue('search'));
      if (isInvalidAddressSearch) {
        return this.setState({ isInvalidAddressSearch: true });
      }

      if (!err) {
        saveFormData(values);
      }
    });
  };

  handleCountryChange = () => {
    const { form } = this.props;
    // Clear the selected state field once the country has been changed
    if (form.getFieldValue('state')) {
      form.resetFields(['state']);
    }
  };

  fillAutoCompleteFields = (values) => {
    const { form } = this.props;
    // Define here the equivalent address fields to match the google API results
    // [googlekey] : [formFieldName]
    const transformSchema = {
      country: 'country',
      postal_code: 'postalcode',
      route: 'street1',
      locality: 'city',
      administrative_area_level_1: 'state',
    };

    const fieldsValue = {};

    Object.keys(transformSchema).forEach((key) => {
      if (has(values, key)) {
        // Put the street number before the street name
        if (key === 'route' && values.street_number) {
          fieldsValue[transformSchema[key]] = `${values.street_number} ${values[key]}`;
        } else {
          fieldsValue[transformSchema[key]] = values[key];
        }
      } else {
        // Clear the fields that don't have a proper value
        fieldsValue[transformSchema[key]] = '';
      }
    });

    this.setState({
      isAddressSectionOpen: true,
    }, () => {
      form.setFieldsValue(fieldsValue);
    });
  };

  togglePropertyDetails = () => {
    this.setState((prevState) => ({
      isPropertyDetailsOpen: !prevState.isPropertyDetailsOpen,
    }));
  };

  render() {
    const {
      intl,
      form,
      formData,
      isLoading,
      isSaving,
      isSaveCompleted,
      isSaveSuccess,
    } = this.props;
    const { isPropertyDetailsOpen, isAddressSectionOpen, isInvalidAddressSearch } = this.state;
    const { formatMessage, locale } = intl;
    const { countryStateData } = formData;
    const { getFieldDecorator, getFieldValue } = form;

    const countryValue = form.getFieldValue('country');
    const selectedCountry = countryValue
      && countryStateData.find((c) => c.countryKey === countryValue);
    const propertyOtherValue = getFieldValue('property_type');
    const evalOtherValue = getFieldValue('eval_reason');

    const evalReasons = [
      { label: formatMessage({ id: 'amelieHomeEvaluation.form.reason.curious' }) },
      { label: formatMessage({ id: 'amelieHomeEvaluation.form.reason.thinking' }) },
      { label: formatMessage({ id: 'amelieHomeEvaluation.form.reason.other' }) },
    ];

    const localesTransform = {
      'en-us': 'US',
      'en-ca': 'CA',
    };

    // Get the properly labels for country and state field according
    // to the current location or current selected country
    const currentLocale = countryValue || localesTransform[locale];
    const provinceLabel = formatMessage({ id: `amelieHomeEvaluation.form.address.${currentLocale === 'US' ? 'state' : 'province'}` });
    const postalCodeLabel = formatMessage({ id: `amelieHomeEvaluation.form.address.${currentLocale === 'US' ? 'zipCode' : 'postalCode'}` });

    // Generates the default validation messages.
    const leadCaptureRequirePhone = Utils.getDefaultConfig('leadCaptureRequirePhone');

    const addressFieldProps = {};

    if (isInvalidAddressSearch && !isAddressSectionOpen) {
      addressFieldProps.help = formatMessage({ id: 'amelieHomeEvaluation.form.address.searchInvalid' });
      addressFieldProps.validateStatus = 'error';
    }

    const countryOptions = countryStateData.map(
      (item) => ({ value: item.countryKey, label: item.country })
    );

    let stateOptions = [];
    if (countryValue && has(selectedCountry, 'states')) {
      stateOptions = Object.keys(selectedCountry.states).map(
        (stateKey) => ({ value: stateKey, label: selectedCountry.states[stateKey] })
      );
    }

    return (
      <Form className="amelie-eval-form" onSubmit={this.handleSubmit}>
        {isSaveCompleted && !isSaveSuccess && (
          <div className="amelie-eval-form-message">
            {intl.formatMessage({ id: 'amelieHomeEvaluation.form.alert.error' })}
          </div>
        )}
        <div className="amelie-eval-section amelie-eval-address-section">
          <h3>
            {formatMessage({ id: 'amelieHomeEvaluation.form.address.heading' })}
          </h3>
          <FormItem className="amelie-eval-form-item amelie-eval-form-item-search" {...addressFieldProps}>
            {getFieldDecorator('search', {
              rules: [{
                required: true,
                message: getValidationMessage(formatMessage({ id: 'amelieHomeEvaluation.form.address.street' })),
              },
              ],
            })(
              <AddressSearchInput
                placeholder={intl.formatMessage({ id: 'amelieHomeEvaluation.form.address.search' })}
                onSelect={this.fillAutoCompleteFields}
                className="amelie-eval-search"
                iconType="search"
              />
            )}
          </FormItem>
          {isAddressSectionOpen && (
            <div className="amelie-eval-address-form-section">
              <FormItem className="amelie-eval-form-item">
                {getFieldDecorator('street1', {
                  rules: [{
                    required: true,
                    message: getValidationMessage(formatMessage({ id: 'amelieHomeEvaluation.form.address.street' })),
                  }],
                  initialValue: '',
                })(
                  <Input placeholder={formatMessage({ id: 'amelieHomeEvaluation.form.address.street' })} />
                )}
              </FormItem>
              <FormItem className="amelie-eval-form-item">
                {getFieldDecorator('street2')(
                  <Input placeholder={formatMessage({ id: 'amelieHomeEvaluation.form.address.line2' })} />
                )}
              </FormItem>
              <div className="column-50">
                <FormItem className="amelie-eval-form-item">
                  {getFieldDecorator('city', {
                    rules: [{
                      required: true,
                      message: getValidationMessage(formatMessage({ id: 'amelieHomeEvaluation.form.address.city' })),
                    }],
                    initialValue: '',
                  })(
                    <Input placeholder={formatMessage({ id: 'amelieHomeEvaluation.form.address.city' })} />
                  )}
                </FormItem>
                <FormItem
                  className="amelie-eval-form-item"
                  // Since antd will not update the validation rules if they change
                  // We need to use the help prop to force it
                  help={form.getFieldError('state') && getValidationMessage(provinceLabel, true, 'select')}
                >
                  {getFieldDecorator('state', {
                    rules: [{
                      required: true,
                    }],
                  })(
                    <Select
                      showArrow={false}
                      isDisabled={isLoading || !countryValue}
                      placeholder={provinceLabel}
                      options={stateOptions}
                    />
                  )}
                </FormItem>
              </div>
              <div className="column-50">
                <FormItem
                  className="amelie-eval-form-item"
                  help={form.getFieldError('postalcode') && getValidationMessage(postalCodeLabel)}
                >
                  {getFieldDecorator('postalcode', {
                    rules: [{
                      required: true,
                    }],
                    initialValue: '',
                  })(
                    <Input placeholder={postalCodeLabel} />
                  )}
                </FormItem>
                <FormItem className="amelie-eval-form-item">
                  {getFieldDecorator('country', {
                    rules: [{
                      required: true,
                      message: getValidationMessage(formatMessage({ id: 'amelieHomeEvaluation.form.address.country' }), true, 'select'),
                    }],
                  })(
                    <Select
                      showArrow={false}
                      isDisabled={isLoading}
                      placeholder={formatMessage({ id: 'amelieHomeEvaluation.form.address.country' })}
                      onChange={this.handleCountryChange}
                      options={countryOptions}
                    />
                  )}
                </FormItem>
              </div>
            </div>
          )}
        </div>
        {!isPropertyDetailsOpen && (
          <div className="amelie-eval-section amelie-eval-expand">
            <Button onClick={this.togglePropertyDetails} className="amelie-eval-details-button">
              <Icon type="plus" className="amelie-eval-details-buttion-icon" />
              <span>Add more details</span>
            </Button>
          </div>
        )}
        {isPropertyDetailsOpen && (
          <div className="amelie-eval-section amelie-eval-property">
            <h3>Property details</h3>
            <FormItem className="amelie-eval-prop-type">
              {getFieldDecorator('property_type')(
                <Radio.Group disabled={isLoading} className="amelie-eval-radio-group">
                  {formData.homeTypes.map((type) => (
                    <Radio key={type.type} value={String(type.id)}>
                      {type.type}
                    </Radio>
                  ))}
                </Radio.Group>
              )}
              {propertyOtherValue === '100' && getFieldDecorator('property_type_other')(
                <Input className="amelie-eval-other-input" placeholder={formatMessage({ id: 'amelieHomeEvaluation.form.property.otherInput' })} />
              )}
            </FormItem>
            <div className="column-33">
              <FormItem className="amelie-eval-form-item">
                {getFieldDecorator('bedroom')(
                  <Input
                    type="number"
                    placeholder={formatMessage({ id: 'amelieHomeEvaluation.form.property.bedPlaceholder' })}
                    suffix={formatMessage({ id: 'amelieHomeEvaluation.form.property.bed' })}
                  />
                )}
              </FormItem>
              <FormItem className="amelie-eval-form-item">
                {getFieldDecorator('bath')(
                  <Input
                    type="number"
                    placeholder={formatMessage({ id: 'amelieHomeEvaluation.form.property.bathPlaceholder' })}
                    suffix={formatMessage({ id: 'amelieHomeEvaluation.form.property.bath' })}
                  />
                )}
              </FormItem>
              <FormItem className="amelie-eval-form-item">
                {getFieldDecorator('sqft')(
                  <Input
                    type="number"
                    placeholder={formatMessage({ id: 'amelieHomeEvaluation.form.property.sqftPlaceholder' })}
                    suffix={formatMessage({ id: 'amelieHomeEvaluation.form.property.sqft' })}
                  />
                )}
              </FormItem>
            </div>
            <FormItem>
              {getFieldDecorator('details')(
                <Input.TextArea className="amelie-eval-details-textarea" placeholder={formatMessage({ id: 'amelieHomeEvaluation.form.property.detailsPlaceholder' })} />
              )}
            </FormItem>
          </div>
        )}
        <div className="amelie-eval-section amelie-eval-contact-section">
          <h3>Contact details</h3>
          <div className="column-50 column-50-mob">
            <FormItem className="amelie-eval-form-item">
              {getFieldDecorator('first_name', {
                rules: [{
                  required: true,
                  message: getValidationMessage(formatMessage({ id: 'amelieHomeEvaluation.form.contact.firstName' })),
                }],
              })(
                <Input placeholder={formatMessage({ id: 'amelieHomeEvaluation.form.contact.firstName' })} />
              )}
            </FormItem>
            <FormItem className="amelie-eval-form-item">
              {getFieldDecorator('last_name', {
                rules: [{
                  required: true,
                  message: getValidationMessage(formatMessage({ id: 'amelieHomeEvaluation.form.contact.lastName' })),
                }],
              })(
                <Input placeholder={formatMessage({ id: 'amelieHomeEvaluation.form.contact.lastName' })} />
              )}
            </FormItem>
          </div>
          <div className="column-50">
            <FormItem className="amelie-eval-form-item">
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    type: 'email',
                    message: getValidationMessage('email address', false),
                  },
                ],
              })(
                <Input placeholder={formatMessage({ id: 'amelieHomeEvaluation.form.contact.email' })} />
              )}
            </FormItem>
            <FormItem className="amelie-eval-form-item">
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: leadCaptureRequirePhone,
                    message: getValidationMessage('phone number'),
                  },
                  {
                    pattern: /^\d{3}-\d{3}-\d{4}$/,
                    message: getValidationMessage('phone number', true),
                  },
                ],
              })(
                <Input.Mask
                  placeholder={leadCaptureRequirePhone ? 'Phone number' : 'Phone number (optional)'}
                  mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                />
              )}
            </FormItem>
          </div>
        </div>
        <div className="amelie-eval-section amelie-eval-reasons">
          <h3>
            {formatMessage({ id: 'amelieHomeEvaluation.form.reason.heading' })}
          </h3>
          <FormItem className="amelie-eval-reason-group">
            {getFieldDecorator('eval_reason')(
              <Radio.Group className="amelie-eval-radio-group">
                {evalReasons.map((type) => (
                  <Radio key={type.label} value={type.label}>
                    {type.label}
                  </Radio>
                ))}
              </Radio.Group>
            )}
            {evalOtherValue === 'Other' && getFieldDecorator('eval_reason_other')(<Input className="amelie-eval-other-input" placeholder={formatMessage({ id: 'amelieHomeEvaluation.form.reason.otherInput' })} />) }
          </FormItem>
        </div>
        <AmelieDisclaimer />
        <Button
          loading={isSaving}
          disabled={isSaving || isLoading}
          type="primary"
          htmlType="submit"
          className="amelie-eval-submit-btn"
        >
          {formatMessage({ id: 'amelieHomeEvaluation.form.submit' })}
        </Button>
      </Form>
    );
  }
}

AmelieHomeEvaluationForm.propTypes = {
  intl: PropTypes.object.isRequired,
};

const mapState = (state) => state.evaluationForm;
const mapDispatch = (actions) => actions.evaluationForm;

const enhanced = compose(
  Form.create(),
  connect(mapState, mapDispatch),
  injectIntl
);

export default enhanced(AmelieHomeEvaluationForm);
