// Dependencies.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Icon, Button } from '../AmelieUI';
import Utils from '../../utils/utils';
import { AUTHFUL_HOST } from '../../constants';

import './AmelieSignInForm.scss';

// Define component class.
class AmelieSignInForm extends Component {
  UNSAFE_componentWillMount() {
    const { userToken, favoriteMlsListingID, setFavorite, hideForm, getVowTermsOfService,
      removeFromRouter, signIn, loadFields, loadListing, loadFavorites } = this.props;
    if (userToken !== null) {
      removeFromRouter('token');
      removeFromRouter('action');
      signIn(userToken);
      if (Utils.getDefaultConfig('enhancedEnabled')) {
        loadFields();
        loadListing();
      }
      if (Utils.getDefaultConfig('favoritesEnabled')) {
        loadFavorites();
      }
    }
    if (favoriteMlsListingID !== null) {
      removeFromRouter('favorite');
      setFavorite(favoriteMlsListingID);
      hideForm();
    }
    if (Utils.getDefaultConfig('enhancedEnabled')) {
      getVowTermsOfService();
    }
  }

  handleCloseClick = (event) => {
    const { hideForm } = this.props;

    event.preventDefault();
    event.stopPropagation();
    hideForm();
  };

  handleSignInClick = () => {
    const { auth } = this.props;
    const siteID = Utils.getDefaultConfig('siteID') || 1;
    const uri = new URL(window.location.href);
    uri.searchParams.set('actionFrom', 'signin');

    if (auth.isSettingFavorite) {
      const mlsListingID = Utils.getDefaultConfig('mlsListingID');
      uri.searchParams.set('favorite', mlsListingID);
    }

    const redirectUrl = uri.toString();

    const url = `${AUTHFUL_HOST}google/login?siteID=${siteID}&redirect_url=${encodeURIComponent(redirectUrl)}`;
    window.location = url;
  };

  // Render the component.
  render() {
    const { intl, auth, userToken } = this.props;
    if (userToken === null && auth.userToken === null && auth.isDisplayed) {
      return (
        <div className="amelie-sign-in-form-wrapper">
          <div className="amelie-sign-in">
            <div className="amelie-sign-in-header">
              <h2>
                {intl.formatMessage({ id: 'amelieSignInForm.header' })}
              </h2>
              <Icon type="close" className="amelie-sign-in-header-icon" onClick={this.handleCloseClick} />
            </div>
            <div className="amelie-sign-in-body">
              <h2>
                {intl.formatMessage({ id: 'amelieSignInForm.subtitle' })}
              </h2>
              <div className="amelie-services">
                {Utils.getDefaultConfig('favoritesEnabled') && (
                  <p className="favorites-enabled">
                    <Icon type="heart-o" className="amelie-service-icon" />
                    {intl.formatMessage({ id: 'amelieSignInForm.favoritesEnabled' })}
                  </p>
                )}
                {Utils.getDefaultConfig('enhancedEnabled') && (
                  <p className="enhanced-enabled">
                    <Icon type="key" className="amelie-service-icon" />
                    {intl.formatMessage({ id: 'amelieSignInForm.enhancedEnabled' })}
                  </p>
                )}
              </div>
            </div>
            {Utils.getDefaultConfig('enhancedEnabled') && auth.vowTermsOfService && (
              <div className="amelie-sign-in-terms">
                <p>
                  {intl.formatMessage({ id: 'amelieSignInForm.terms' })}
                </p>
                <p>
                  {auth.vowTermsOfService}
                </p>
              </div>
            )}
            <div className="amelie-sign-in-footer">
              <Button
                type="primary"
                icon="google"
                className="amelie-sign-in-button"
                onClick={this.handleSignInClick}
              >
                {intl.formatMessage({ id: 'amelieSignInForm.signInGoogle' })}
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

// Property validation.
AmelieSignInForm.propTypes = {
  // Properties.
  auth: PropTypes.object.isRequired,
  userToken: PropTypes.string,
  favoriteMlsListingID: PropTypes.string,

  // Functions.
  hideForm: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  loadFields: PropTypes.func.isRequired,
  loadListing: PropTypes.func.isRequired,
  loadFavorites: PropTypes.func.isRequired,
  setFavorite: PropTypes.func.isRequired,
  removeFromRouter: PropTypes.func.isRequired,
  getVowTermsOfService: PropTypes.func.isRequired,
};

// Default prop values.
AmelieSignInForm.defaultProps = {
  userToken: null,
  favoriteMlsListingID: null,
};

export default injectIntl(AmelieSignInForm);
