import React from 'react';
import { Button, Radio, Checkbox, Input, Select } from '../AmelieUI';

import './AmelieUIDemo.scss';

function AmelieUIDemo() {
  return (
    <div className="amelie-ui-demo">
      <h3>Buttons</h3>
      <div className="amelie-ui-demo-buttons">
        <Button>
          Default Button
        </Button>
        <Button loading>
          Default Loading
        </Button>
        <Button disabled>
          Default Disabled
        </Button>
      </div>
      <div className="amelie-ui-demo-buttons">
        <Button type="primary">
          Primary Button
        </Button>
        <Button type="primary" loading>
          Primary Loading
        </Button>
        <Button type="primary" disabled>
          Primary Disabled
        </Button>
      </div>
      <div className="amelie-ui-demo-buttons">
        <Button icon="google">
          Google
        </Button>
        <Button icon="facebook">
          Facebook
        </Button>
        <Button icon="plus">
          Add a comment
        </Button>
      </div>
      <div className="amelie-ui-demo-buttons">
        <Button small>
          Small Button
        </Button>
        <Button small type="primary">
          Small Primary Button
        </Button>
      </div>
      <div className="amelie-ui-demo-inputs">
        <h3>Inputs</h3>
        <Input placeholder="Please enter your name." />
        <Input icon="pin" placeholder="Please enter your home address" />
        <Input.Mask
          placeholder="Please enter your phone number."
          mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        />
        <Input.TextArea rows={6} placeholder="I'm TextArea" />
      </div>
      <div>
        <h3>Checkboxes</h3>
        <Checkbox defaultChecked>
          Front-end
        </Checkbox>
        <Checkbox>
          Back-end
        </Checkbox>
      </div>
      <div>
        <h3>Radio Buttons</h3>
        <Radio.Group>
          <Radio name="contact" value="Apple" defaultChecked>
            Apple
          </Radio>
          <Radio name="contact" value="Banana">
            Banana
          </Radio>
          <Radio name="contact" value="Orange">
            Orange
          </Radio>
        </Radio.Group>
      </div>
      <div>
        <h3>Select</h3>
        <Select
          placeholder="Which flavor do you want?"
          options={[
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' },
          ]}
        />
      </div>
    </div>
  );
}

export default AmelieUIDemo;
