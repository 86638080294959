import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Button } from '../AmelieUI';
import './AmelieUserToolbar.scss';

function AmelieUserToolbar({ 
  auth,
  signOut,
  intl,
}) {
  useEffect(() => {
    // Add logged-in class when mounted if authenticated
    if (auth.userToken) {
      document.body.classList.add('amelie-logged-in');
    }

    // Clean up when unmounting
    return () => {
      // Only remove the class if this instance added it
      if (auth.userToken) {
        document.body.classList.remove('amelie-logged-in');
      }
    };
  }, [auth.userToken]); // Re-run if auth token changes

  if (!auth.userToken) {
    return null;
  }

  return (
    <div className="amelie-user-toolbar">
      <div className="amelie-user-toolbar-content">
        <span className="amelie-user-toolbar-name">
          {auth.userName && auth.userName !== 'Guest User' 
            ? `Welcome, ${auth.userName}`
            : intl.formatMessage({ id: 'amelieUserToolbar.welcome' })}
        </span>
        <Button 
          onClick={signOut}
          className="amelie-user-toolbar-signout"
          small
        >
          {intl.formatMessage({ id: 'amelieSignOutLink.signOut' })}
        </Button>
      </div>
    </div>
  );
}

AmelieUserToolbar.propTypes = {
  auth: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(AmelieUserToolbar);