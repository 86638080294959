/**
 * AmelieExpandedDetails
 * @package   rb-amelie
 * @author    Deiva Magalhaes <deiva@avenuehq.com>
 * @license   None
 * @link      https://avenuehq.com
 * @copyright    2017 AvenueHQ
 */
// Dependencies.
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import exists from 'exists';
import { injectIntl } from 'react-intl';
import AmelieEnhancedField from '../AmelieEnhancedField/AmelieEnhancedField';

// Define component class.
class AmelieEnhancedFields extends PureComponent {
  // Render the component.
  render() {
    const { intl, fields, location, listing } = this.props;
    const filteredFields = fields.filter((field) => field[`display_${location}`] && exists(listing[field.field]));
    if (!filteredFields || filteredFields.length === 0) {
      return null;
    }
    const fieldsInformation = filteredFields.map((field) => {
      return (
        <AmelieEnhancedField
          key={field.field}
          field={field}
          value={listing[field.field]}
          location={location}
        />
      );
    });
    return (
      <div>
        { location === 'main' && (
          <h2 className="listing-subheading">
            {intl.formatMessage({ id: 'amelieEnhancedFields.expandDetails' })}
          </h2>
        )}
        { location === 'sidebar' && (
          <li className="listing-price">
            <span className="listing-term">
              {intl.formatMessage({ id: 'amelieEnhancedFields.expandDetails' })}
            </span>
          </li>
        )}
        { fieldsInformation }
      </div>
    );
  }
}

// Property validation.
AmelieEnhancedFields.propTypes = {
  // Properties.
  fields: PropTypes.array.isRequired,
  listing: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.string.isRequired,

  // Functions.
};

export default injectIntl(AmelieEnhancedFields);
