import React, { Component, createRef } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AmelieHomeEvaluationForm from '../AmelieHomeEvaluationForm/AmelieHomeEvaluationForm';
import Utils from '../../utils/utils';
import './AmelieHomeEvaluation.scss';

class AmelieHomeEvaluation extends Component {
  constructor(props) {
    super(props);
    this.feedbackMessageRef = createRef();
  }

  componentDidUpdate(prevProps) {
    const { formSubmitted: prevFormSubmitted } = prevProps;
    const { formSubmitted: curFormSubmitted } = this.props;

    if (!prevFormSubmitted && curFormSubmitted) {
      Utils.sendToGoogle('homeEvaluation');

      window.scrollTo({
        // Add an extra margin before the message content
        top: this.feedbackMessageRef.current.offsetTop - 200,
        behavior: 'smooth',
      });
    }
  }

  render() {
    const { intl, formSubmitted, cache } = this.props;
    return (
      <div className="amelie-home-eval">
        {formSubmitted ? (
          <div
            ref={this.feedbackMessageRef}
            className="amelie-eval-header submitted"
          >
            <h2>
              {intl.formatMessage(
                { id: 'amelieHomeEvaluation.form.alert.success' },
                { name: cache.first_name }
              )}
            </h2>
          </div>
        ) : (
          <>
            <div className="amelie-eval-header">
              <h2>
                {intl.formatMessage({ id: 'amelieHomeEvaluation.title' })}
              </h2>
              <p>
                {intl.formatMessage({ id: 'amelieHomeEvaluation.instruction' })}
              </p>
            </div>
            <AmelieHomeEvaluationForm />
          </>
        )}
      </div>
    );
  }
}

const mapState = ({ evaluationForm }) => ({
  formSubmitted: Boolean(evaluationForm.isSaveCompleted && evaluationForm.isSaveSuccess),
  cache: evaluationForm.formData.cache,
});

const enhanced = compose(
  connect(mapState),
  injectIntl
);

export default enhanced(AmelieHomeEvaluation);
