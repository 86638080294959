// TODO - Get these from Authful API

export const SOCIAL_LEAD_CAPTURE = 'SOCIAL_LEAD_CAPTURE';
export const SAVED_SEARCH = 'SAVED_SEARCH';
export const LEAD_CAPTURE_BUBBLE = 'LEAD_CAPTURE_BUBBLE';
export const SOCIAL_SIGN_IN = 'SOCIAL_SIGN_IN';
export const FORCED_LEAD_CAPTURE = 'FORCED_LEAD_CAPTURE';
export const ARRANGE_SHOWING_BUTTON = 'ARRANGE_SHOWING_BUTTON';
export const PREDEFINED_SEARCH_LEAD_CAPTURE = 'PREDEFINED_SEARCH_LEAD_CAPTURE';
export const PROMPTED_LEAD_CAPTURE = 'PROMPTED_LEAD_CAPTURE';
export const REVIEW = 'REVIEW';
export const REQUEST_INFORMATION_BUTTON = 'REQUEST_INFORMATION_BUTTON';
export const CONNECT_FORM = 'CONNECT_FORM';

export const getLeadSources = () => [
  {
    name: SOCIAL_LEAD_CAPTURE,
    label: 'Social Lead Capture',
    id: 1,
  },
  {
    name: SAVED_SEARCH,
    label: 'Saved Search',
    id: 2,
  },
  {
    name: SOCIAL_SIGN_IN,
    label: 'Social Sign In',
    id: 3,
  },
  {
    name: LEAD_CAPTURE_BUBBLE,
    label: 'Lead Capture Bubble',
    id: 4,
  },
  {
    name: FORCED_LEAD_CAPTURE,
    label: 'Forced Lead Capture',
    id: 5,
  },
  {
    name: ARRANGE_SHOWING_BUTTON,
    label: 'Arrange Showing Button',
    id: 6,
  },
  {
    name: PREDEFINED_SEARCH_LEAD_CAPTURE,
    label: 'Predefined Search Lead Capture',
    id: 9,
  },
  {
    name: PROMPTED_LEAD_CAPTURE,
    label: 'Prompted Lead Capture',
    id: 10,
  },
  {
    name: REVIEW,
    label: 'Review',
    id: 11,
  },
  {
    name: REQUEST_INFORMATION_BUTTON,
    label: 'Request Information Button',
    id: 12,
  },
  {
    name: CONNECT_FORM,
    label: 'Connect Form',
    id: 13,
  },
];

export const getLeadSourceIdByName = (name) => {
  return getLeadSources().find((leadSource) => leadSource.name === name).id;
};

export const getValidationMessage = (field, required = true, verb = 'enter') => {
  const fieldText = field.toLowerCase();

  if (required) {
    return `Please ${verb} your ${fieldText}.`;
  } else {
    return `Please ${verb} a valid ${fieldText}.`;
  }
};
