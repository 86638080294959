/* eslint-disable react/no-danger */

import React, { forwardRef, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import classNames from 'classnames';
import has from 'lodash/has';
import { Input, Icon } from '..';
import { GOOGLE_API_KEY } from '../../../constants';

import './AddressSearchInput.scss';

const AddressSearchInput = forwardRef(({
  iconType,
  className,
  placeholder,
  onSelect,
  onChange,
}, ref) => {
  const {
    placesService,
    getPlacePredictions,
    placePredictions,
    isPlacePredictionsLoading: loading,
  } = useGoogle({
    apiKey: GOOGLE_API_KEY,
    options: {
      componentRestrictions: {
        country: ['us', 'ca'],
      },
    },
  });

  const UNSELECTED = -1;
  const [value, setValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef();
  const [menuIndex, setMenuIndex] = useState(UNSELECTED);

  const handleListItemClick = async (place) => {
    setValue(place.description);
    placesService.getDetails({
      placeId: place.place_id,
    }, (detail) => {
      const address = {};

      // Defines which address fields we need to extract from Google Geolocation API
      const geolocationTypes = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'short_name',
        postal_code: 'short_name',
      };

      // Get each component of the address from the place details
      // and fill the corresponding field on the form.
      const { address_components: addressComponents } = detail;
      addressComponents.forEach((item) => {
        const addressType = item.types[0];
        if (addressType && has(geolocationTypes, addressType)) {
          const val = item[geolocationTypes[addressType]];
          address[addressType] = val;
        }
      });

      setShowDropdown(false);

      if (onSelect) {
        onSelect(address);
      }

      if (onChange) {
        onChange(value);
      }
    });
  };

  const handleChange = async (search) => {
    try {
      getPlacePredictions({
        input: search,
        types: ['geocode'],
      });
      setValue(search);
      setShowDropdown(true);
      setMenuIndex(UNSELECTED);
    } catch (err) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }

      return false;
    };

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  const handleFocus = () => {
    setShowDropdown(true);
  };

  const handleKeyDown = (e) => {
    if (!(showDropdown && placePredictions.length > 0)) {
      return;
    }

    if (e.key === 'ArrowUp' && menuIndex > 0) {
      setMenuIndex(menuIndex - 1);
    } else if (e.key === 'ArrowDown') {
      if (menuIndex === UNSELECTED) {
        return setMenuIndex(0);
      }
      if (menuIndex < placePredictions.length - 1) {
        setMenuIndex(menuIndex + 1);
      }
    } else if (e.key === 'Enter' && menuIndex >= 0) {
      e.preventDefault();
      handleListItemClick(placePredictions[menuIndex]);
    } else if (e.key === 'Escape') {
      setShowDropdown(false);
      setMenuIndex(UNSELECTED);
    }
  };

  return (
    <div className="amelie-address-search-container">
      <Input
        ref={ref}
        className={classNames('amelie-address-search-input', className)}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
        icon={iconType}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
      />
      {!loading && showDropdown && placePredictions.length > 0 && (
        <div
          className="amelie-address-search-dropdown"
          ref={dropdownRef}
        >
          {placePredictions.map((place, index) => {
            // wrap the value with a bold tag
            const content = place.description.replace(new RegExp(`(${value})`, 'gi'), '<b>$1</b>');

            return (
              <div
                key={place.place_id}
                className={classNames('amelie-address-search-dropdown-listitem', {
                  focused: index === menuIndex,
                })}
                onClick={() => handleListItemClick(place)}
              >
                <Icon type="pin" className="icon-listitem" />
                <span dangerouslySetInnerHTML={{
                  __html: content,
                }}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
});
AddressSearchInput.displayName = 'AddressSearchInput';

AddressSearchInput.defaultProps = {
  placeholder: '',
  iconType: 'search',
  className: null,
  onChange: null,
};

AddressSearchInput.propTypes = {
  placeholder: PropTypes.string,
  iconType: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};
export default AddressSearchInput;
