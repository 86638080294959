// Dependencies.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import exists from 'exists';
import * as authActions from '../../redux/auth';
import * as favoritesActions from '../../redux/favorites';
import AmelieFavoriteIcon from '../AmelieFavoriteIcon/AmelieFavoriteIcon';
import Utils from '../../utils/utils';

// Define component class.
class AmelieFavoriteListing extends Component {
  UNSAFE_componentWillMount() {
    // Load favorites, if logged in.
    const { auth, loadFavorites } = this.props;
    if (auth.userToken !== null) {
      loadFavorites();
    }
  }

  componentDidMount() {
    if (window) {
      window.addEventListener('storage', this.localStorageUpdated);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('storage', this.localStorageUpdated);
    }
  }

  localStorageUpdated = () => {
    const { loadFavorites } = this.props;
    if (localStorage.getItem('updateFavorites')) {
      loadFavorites();
    }
  };

  render() {
    const { toggleFavorite } = this.props;
    const mlsListingID = Utils.getDefaultConfig('mlsListingID');
    if (!mlsListingID) {
      return null;
    }
    const {
      favorites,
    } = this.props;
    const active = (exists(favorites[mlsListingID]) && favorites[mlsListingID]);
    return (
      <AmelieFavoriteIcon
        active={active}
        id={mlsListingID}
        toggleFavorite={toggleFavorite}
      />
    );
  }
}

// Property validation.
AmelieFavoriteListing.propTypes = {
  // State.
  favorites: PropTypes.object,

  // Functions.
  toggleFavorite: PropTypes.func.isRequired,
  loadFavorites: PropTypes.func.isRequired,
};

// Default prop values.
AmelieFavoriteListing.defaultProps = {
  favorites: {},
};

const mapStateToProps = ({ auth, favorites }) => ({
  auth,
  favorites,
});

const mapDispatchToProps = (dispatch) => {
  const actionCreators = {
    ...authActions,
    ...favoritesActions,
  };
  return bindActionCreators(actionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AmelieFavoriteListing);

// For tests.
export { AmelieFavoriteListing as PureAmelieFavoriteListing };
