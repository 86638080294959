import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../AmelieUI';

import './AmelieLandingConfirmation.scss';

function AmelieLandingConfirmation({ showBackButton, title }) {
  return (
    <div className="amelie-landing-complete">
      <h2 className="amelie-landing-complete-title">
        {title}
      </h2>
      {showBackButton && (
      <Button
        className="amelie-landing-complete-button"
        type="primary"
        onClick={() => { window.location = '/'; }}
      >
        Back
      </Button>
      )}
    </div>
  );
}

AmelieLandingConfirmation.propTypes = {
  showBackButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

AmelieLandingConfirmation.defaultProps = {
  showBackButton: false,
};

export default AmelieLandingConfirmation;
