import React from 'react';
import PropTypes from 'prop-types';

/*
Replaced SVG loader from `react-app-rewire-svg-react-loader` to built-in create-react-app module.
https://facebook.github.io/create-react-app/docs/adding-images-fonts-and-files#adding-svgs

However, they don't support dynamic import due to some issues.
https://github.com/facebook/create-react-app/issues/5276

We can replace below this if the issue is resolved.
*/

import { ReactComponent as check } from 'assets/icons/check.svg';
import { ReactComponent as close } from 'assets/icons/close.svg';
import { ReactComponent as edit } from 'assets/icons/edit.svg';
import { ReactComponent as facebook } from 'assets/icons/facebook.svg';
import { ReactComponent as google } from 'assets/icons/google.svg';
import { ReactComponent as heartO } from 'assets/icons/heart-o.svg';
import { ReactComponent as heart } from 'assets/icons/heart.svg';
import { ReactComponent as home } from 'assets/icons/home.svg';
import { ReactComponent as key } from 'assets/icons/key.svg';
import { ReactComponent as left } from 'assets/icons/left.svg';
import { ReactComponent as loading } from 'assets/icons/loading.svg';
import { ReactComponent as phone } from 'assets/icons/phone.svg';
import { ReactComponent as pin } from 'assets/icons/pin.svg';
import { ReactComponent as plus } from 'assets/icons/plus.svg';
import { ReactComponent as right } from 'assets/icons/right.svg';
import { ReactComponent as save } from 'assets/icons/save.svg';
import { ReactComponent as search } from 'assets/icons/search.svg';
import { ReactComponent as send } from 'assets/icons/send.svg';

const icon = {
  check,
  close,
  edit,
  google,
  facebook,
  'heart-o': heartO,
  heart,
  home,
  key,
  left,
  loading,
  phone,
  pin,
  plus,
  right,
  save,
  search,
  send,
};

function Icon({ type, ...restProps }) {
  const Component = icon[type];

  if (!Component) {
    return null;
  }
  return <Component {...restProps} />;
}

Icon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Icon;
