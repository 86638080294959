/**
 * Helper functions to interface with the LOL API.
 */
// Dependencies.
import exists from 'exists';
import Utils from '../utils/utils';
import { API_ENV, LOL_HOST, LOL_KEY } from '../constants';

function buildlolURL(lolURL) {
  // change lol url to /carmen-api on prod
  if (API_ENV.NAME === 'production') {
    const relativePath = lolURL.toString().substring(lolURL.origin.length);
    return `/carmen-api${relativePath}`;
  } else {
    return lolURL.toString();
  }
}

/**
 * Build a URL to query the LOL API for listing fields by board and license.
 *
 * @returns {string}
 */
function buildFieldsURL() {
  const vendor = Utils.getVendor();
  const lolBase = exists(vendor.subboard)
    ? `${vendor.board}/${vendor.subboard}/meta/listings/enhanced_fields`
    : `${vendor.board}/meta/listings/enhanced_fields`;

  const lolURL = new URL(lolBase, LOL_HOST);
  if (LOL_KEY) {
    lolURL.searchParams.set('access_token', LOL_KEY);
  }
  return buildlolURL(lolURL);
}

/**
 * Build a URL to query the LOL API for a specific listing by MLS Listing ID, board and license.
 *
 * @param userToken
 * @returns {string}
 */
function buildListingURL(userToken = null) {
  const vendor = Utils.getVendor();
  let lolBase = `${LOL_HOST}${vendor.board}`;
  if (exists(vendor.subboard)) {
    lolBase = `${lolBase}/${vendor.subboard}/listings`;
  } else {
    lolBase = `${lolBase}/listings`;
  }
  if (userToken !== null) {
    // Retrieve VOW listings too (gets both IDX and VOW listings).
    lolBase = `${lolBase}/vow`;
  } else {
    lolBase = `${lolBase}/idx`;
  }
  const lolURL = new URL(lolBase, LOL_HOST);
  if (LOL_KEY) {
    lolURL.searchParams.set('access_token', LOL_KEY);
  }
  lolURL.searchParams.set('mlsListingID[]', Utils.getDefaultConfig('mlsListingID'));
  return buildlolURL(lolURL);
}

/**
 * Build a URL to query the LOL API for rooms by listing ID, board and license.
 *
 * @param listingID
 * @param userToken
 * @returns {string}
 */
function buildListingRoomsURL(listingID, userToken = null) {
  const vendor = Utils.getVendor();
  let lolBase = `${LOL_HOST}${vendor.board}`;
  if (exists(vendor.subboard)) {
    lolBase = `${lolBase}/${vendor.subboard}/listings`;
  } else {
    lolBase = `${lolBase}/listings`;
  }
  if (userToken !== null) {
    // Retrieve VOW listings too (gets both IDX and VOW listings).
    lolBase = `${lolBase}/vow`;
  } else {
    lolBase = `${lolBase}/idx`;
  }
  lolBase = `${lolBase}/${listingID}/rooms`;
  const lolURL = new URL(lolBase, LOL_HOST);
  if (LOL_KEY) {
    lolURL.searchParams.set('access_token', LOL_KEY);
  }
  return buildlolURL(lolURL);
}

/**
 * Build LOL URL for vendor information.
 *
 * @returns {string}
 */
function buildVendorURL() {
  const vendor = Utils.getVendor();

  const lolBase = `${LOL_HOST}vendors/${vendor.board}`;
  const lolURL = new URL(lolBase, LOL_HOST);
  if (LOL_KEY) {
    lolURL.searchParams.set('access_token', LOL_KEY);
  }
  return buildlolURL(lolURL);
}

export default {
  buildFieldsURL,
  buildListingURL,
  buildListingRoomsURL,
  buildVendorURL,
};
