import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

class AmelieSignInLink extends Component {
  render() {
    const { auth, showForm, intl } = this.props;
    return (
      <>
        {!auth.userToken && (
          // Must be a link because of WP.
          <a className="menu-button" onClick={() => showForm(false)} onKeyDown={() => showForm(false)}>
            {intl.formatMessage({ id: 'amelieSignInLink.signIn' })}
          </a>
        )}
      </>
    );
  }
}

AmelieSignInLink.propTypes = {
  // Properties.
  auth: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,

  // Functions.
  showForm: PropTypes.func.isRequired,
};

export default injectIntl(AmelieSignInLink);
