/**
 * AmelieExpandedDetails
 * @package   rb-amelie
 * @author    Deiva Magalhaes <deiva@avenuehq.com>
 * @license   None
 * @link      https://avenuehq.com
 * @copyright    2017 AvenueHQ
 */
// Dependencies.
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

// Define component class.
class AmelieEnhancedField extends PureComponent {
  // Render the component.
  render() {
    const { location, value, field } = this.props;
    const newValue = field.type === 'decimal' ? numeral(value).format('$0,0') : value;

    if (newValue) {
      if (field.display_main && location === 'main') {
        return (
          <div key={field.field}>
            <span>
              {field.display_name}
              :&nbsp;
            </span>
            <span>
              {newValue}
            </span>
          </div>
        );
      } else if (field.display_sidebar && location === 'sidebar') {
        return (
          <li key={field.field}>
            <span className="listing-term">
              {field.display_name}
            </span>
            <span className="listing-value">
              {newValue}
            </span>
          </li>
        );
      }
    }
    return null;
  }
}

// Property validation.
AmelieEnhancedField.propTypes = {
  // Properties.
  field: PropTypes.object.isRequired,
  value: PropTypes.any,
  location: PropTypes.string.isRequired,

  // Functions.
};

// Default prop values.
AmelieEnhancedField.defaultProps = {
  value: null,
};

export default AmelieEnhancedField;
