/* eslint-disable operator-linebreak */

import request from 'superagent';
import moment from 'moment';
import { AUTHFUL_HOST } from '../constants';
import Utils from '../utils/utils';
import { getLeadSourceIdByName } from '../utils/leads';

const HIDE_LEAD_CAPTURE_BUBBLE = 'HIDE_LEAD_CAPTURE_BUBBLE';

const currentDate = () => moment().format('YYYY-MM-DD');

const initialState = {
  visibleBubbleMessage: window.sessionStorage.getItem(HIDE_LEAD_CAPTURE_BUBBLE) !== currentDate(),
};

export default {
  state: initialState,
  reducers: {
    setVisibleBubbleMessage: (state, payload) => ({ ...state, visibleBubbleMessage: payload }),
  },
  effects: (dispatch) => ({
    async hideBubbleMessage() {
      window.sessionStorage.setItem(HIDE_LEAD_CAPTURE_BUBBLE, currentDate());
      await dispatch.leadCapture.setVisibleBubbleMessage(false);
    },
    async sendLead(data) {
      const leadSourceId = getLeadSourceIdByName(data.source);
      const queryData = {
        ...data,
        site_id: Utils.getDefaultConfig('siteID') || 1,
        source_page_title: document.title || '',
        source_page_url: document.URL,
        lead_source_id: leadSourceId,
      };
      const response = await request.post(`${AUTHFUL_HOST}api/v1/lead-capture-submissions`).query(queryData);
      if (!response.ok) {
        return false;
      }
    },
    async sendBubbleFormData(data) {
      await dispatch.leadCapture.sendLead(data);
      window.sessionStorage.setItem(HIDE_LEAD_CAPTURE_BUBBLE, currentDate());
    },
    async sendPLCLead({
      sourceUrl,
      isPredefinedSearch,
      data,
    }) {
      const query = {
        ...data,
        site_id: Utils.getDefaultConfig('siteID') || 1,
      };

      const postUrl = `${AUTHFUL_HOST}api/v1/${isPredefinedSearch ? 'predefined-search-submissions' : 'prompted-lead-capture-submissions'}`;
      const response = await request.post(postUrl).query(query);
      if (!response.ok) {
        return false;
      }

      const googleAds = Utils.getDefaultConfig('googleAds');
    
      if (googleAds?.conversionLabels?.predefinedSearchLeadCapturePage) {
        // The origins "Property Search Page to Track" is the predefined list page
        // We need to check if the cookie fromPredefinedSearch URL contains the same URL.
  
        if (sourceUrl && sourceUrl.includes(googleAds.conversionLabels.predefinedSearchLeadCapturePage)) {
          Utils.sendToGoogle('predefinedSearchLeadCaptureLabel');
        }
      }
    },
  }),
};
