// favorites.js

/**
 * Dependencies.
 */

import exists from 'exists';
import request from 'superagent';
import Utils from '../utils/utils';
import { AUTHFUL_HOST } from '../constants';
import * as authActions from './auth';

/**
 * Actions.
 */

export const SET_FAVORITE = 'rb-amelie/favorites/SET_FAVORITE';
export const RESET_FAVORITE = 'rb-amelie/favorites/RESET_FAVORITE';
export const LOAD_FAVORITES = 'rb-amelie/favorites/LOAD_FAVORITES';

// Actions from auth.js
export const SHOW_FORM = 'rb-amelie/auth/SHOW_FORM';

/**
 * Local variables.
 */

const maxAttempts = 3;
let favFailures = 0;

/**
 * Reducer.
 */

const initialState = {};

const authfulFavoriteURL = `${AUTHFUL_HOST}api/v1/favorite-listings`;

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_FAVORITE: {
      return {
        ...state,
        [action.mlsListingID]: true,
      };
    }

    case RESET_FAVORITE: {
      const newValue = { ...state };
      delete newValue[action.mlsListingID];
      return newValue;
    }

    case LOAD_FAVORITES: {
      return action.favorites;
    }

    default: {
      return state;
    }
  }
}

/**
 * Action creators.
 */

export function toggleFavorite(mlsListingID) {
  return (dispatch, getState) => {
    if (favFailures < maxAttempts) {
      const state = getState();
      const { auth, favorites } = state;

      if (auth.userToken === null) {
        dispatch({ type: SHOW_FORM, isSettingFavorite: true });
      } else {
        const authorizationHeader = `Bearer ${auth.userToken}`;
        const siteID = Utils.getDefaultConfig('siteID') || 1;

        if (exists(favorites[mlsListingID]) && favorites[mlsListingID]) {
          // Delete to Authful API to remove listing from favorites.
          return request.del(authfulFavoriteURL)
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json')
            .set('Authorization', authorizationHeader)
            .send({
              mls_listing_id: mlsListingID.toString(),
              site_id: siteID,
            })
            .timeout({
              response: 5000,
              deadline: 10000,
            })
            .then(() => {
              // Success! Clear the failure count.
              favFailures = 0;
              dispatch({
                type: RESET_FAVORITE,
                mlsListingID,
              });
              // Set local storage so that Carmen knows to reload favorites.
              localStorage.setItem('updateFavorites', 'true');
            })
            .catch((error) => {
              favFailures += 1;
              Utils.debug(error, favFailures);
              if (error.status === 401) {
                dispatch(authActions.signOut());
                dispatch({ type: SHOW_FORM, isSettingFavorite: true });
              } else {
                // Goonies never say die!
                dispatch(toggleFavorite(mlsListingID));
              }
            });
        } else {
          // Post to Authful API to add listing to favorites.
          return request.post(authfulFavoriteURL)
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json')
            .set('Authorization', authorizationHeader)
            .send({
              mls_listing_id: mlsListingID.toString(),
              site_id: siteID,
            })
            .timeout({
              response: 5000,
              deadline: 10000,
            })
            .then(() => {
              // Success! Clear the failure count.
              favFailures = 0;
              dispatch({
                type: SET_FAVORITE,
                mlsListingID,
              });
              // Set local storage so that Carmen knows to reload favorites.
              localStorage.setItem('updateFavorites', 'true');
            })
            .catch((error) => {
              favFailures += 1;
              Utils.debug(error, favFailures);
              if (error.status === 401) {
                dispatch(authActions.signOut());
                dispatch({ type: SHOW_FORM, isSettingFavorite: true });
              } else {
                // Goonies never say die!
                dispatch(toggleFavorite(mlsListingID));
              }
            });
        }
      }
    } else {
      // Too many failures. Log and reset.
      Utils.debug(`Call to Authful API failed after ${maxAttempts} attempts.`);
      favFailures = 0;
    }
  };
}

export function setFavorite(mlsListingID) {
  return (dispatch, getState) => {
    if (favFailures < maxAttempts) {
      const state = getState();
      const { auth, favorites } = state;

      if (auth.userToken === null) {
        dispatch({ type: SHOW_FORM, isSettingFavorite: true });
      } else {
        const authorizationHeader = `Bearer ${auth.userToken}`;
        const siteID = Utils.getDefaultConfig('siteID') || 1;

        if (exists(favorites[mlsListingID]) && favorites[mlsListingID]) {
          // Do nothing.
        } else {
          // Post to Authful API to add listing to favorites.
          return request.post(authfulFavoriteURL)
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json')
            .set('Authorization', authorizationHeader)
            .send({
              mls_listing_id: mlsListingID.toString(),
              site_id: siteID,
            })
            .timeout({
              response: 5000,
              deadline: 10000,
            })
            .then(() => {
              // Success! Clear the failure count.
              favFailures = 0;
              dispatch({
                type: SET_FAVORITE,
                mlsListingID,
              });
              // Set local storage so that Carmen knows to reload favorites.
              localStorage.setItem('updateFavorites', 'true');
            })
            .catch((error) => {
              favFailures += 1;
              Utils.debug(error, favFailures);
              if (error.status === 401) {
                dispatch(authActions.signOut());
                dispatch({ type: SHOW_FORM, isSettingFavorite: true });
              } else {
                // Goonies never say die!
                dispatch(setFavorite(mlsListingID));
              }
            });
        }
      }
    } else {
      // Too many failures. Log and reset.
      Utils.debug(`Call to Authful API failed after ${maxAttempts} attempts.`);
      favFailures = 0;
    }
  };
}

export function loadFavorites() {
  return (dispatch, getState) => {
    if (favFailures < maxAttempts) {
      const state = getState();
      const { auth } = state;

      if (auth.userToken === null) {
        dispatch({ type: SHOW_FORM, isSettingFavorite: false });
      } else {
        const authorizationHeader = `Bearer ${auth.userToken}`;
        const siteID = Utils.getDefaultConfig('siteID') || 1;

        // Call to Authful API to retrieve favorite listings.
        return request.get(authfulFavoriteURL)
          .set('Content-Type', 'application/json')
          .set('Accept', 'application/json')
          .set('Authorization', authorizationHeader)
          .send({
            site_id: siteID,
          })
          .timeout({
            response: 5000,
            deadline: 10000,
          })
          .then((json) => {
            const { body } = json;
            const favorites = {};
            for (let i = 0, len = body.data.length; i < len; i++) {
              const mlsListingID = (body.data[i]).mls_listing_id;
              favorites[mlsListingID] = true;
            }
            dispatch({
              type: LOAD_FAVORITES,
              favorites,
            });
            // Success! Clear the failure count.
            favFailures = 0;
            // Reset local storage variable.
            localStorage.removeItem('updateFavorites');
          })
          .catch((error) => {
            favFailures += 1;
            Utils.debug(error, favFailures);
            if (error.status === 401) {
              dispatch(authActions.signOut());
            } else {
              // Goonies never say die!
              dispatch(loadFavorites());
            }
          });
      }
    } else {
      // Too many failures. Log and reset.
      Utils.debug(`Call to Authful API failed after ${maxAttempts} attempts.`);
      favFailures = 0;
    }
  };
}
