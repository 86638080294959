import React from 'react';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
} from 'react-share';
import { ReactComponent as XIcon } from 'assets/icons/x.svg';
import Utils from '../../utils/utils';

import styles from './AmelieSharingButtons.module.scss';

function AmelieSharingButtons({
  attrs,
  size,
  round,
}) {
  const url = attrs['data-url'] || window.location.href;
  const title = attrs['data-title'] || document.title;
  const media = attrs['data-media'];
  const sharingButtons = Utils.getDefaultConfig('sharingButtons');

  return (
    <div className={styles.amelieSharingButtons}>
      {sharingButtons.facebook && (
        <FacebookShareButton
          url={url}
          quote={title}
        >
          <FacebookIcon size={size} round={round} />
        </FacebookShareButton>
      )}
      {sharingButtons.twitter && (
        <TwitterShareButton
          url={url}
          title={title}
        >
          <XIcon style={{ width: size, height: size }} />
        </TwitterShareButton>
      )}
      {sharingButtons.linkedin && (
        <LinkedinShareButton url={url}>
          <LinkedinIcon size={size} round={round} />
        </LinkedinShareButton>
      )}
      {sharingButtons.pinterest && (
        <PinterestShareButton
          url={url}
          media={media}
        >
          <PinterestIcon size={size} round={round} />
        </PinterestShareButton>
      )}
    </div>
  );
}

AmelieSharingButtons.defaultProps = {
  size: 32,
  round: true,
};

AmelieSharingButtons.propTypes = {
  size: PropTypes.number,
  round: PropTypes.bool,
};

export default AmelieSharingButtons;
