import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import * as authActions from '../../redux/auth';
import AmelieSignInLink from '../AmelieSignInLink/AmelieSignInLink';
import AmelieSignOutLink from '../AmelieSignOutLink/AmelieSignOutLink';
import Utils from '../../utils/utils';

class AmelieMenu extends Component {
  render() {
    const {
      auth, showForm, signOut, intl,
    } = this.props;

    // Check if savedSearches is enabled
    const favoritesEnabled = Utils.getDefaultConfig('favoritesEnabled');

    // Add/remove class required by WP to render menu items with children properly.
    const menuElement = document.getElementById('amelie-menu');
    if (menuElement) {
      let classes = menuElement.className;
      if (auth.userToken) {
        classes = `${classes} menu-item-has-children`;
      } else {
        classes = classes.replace(/menu-item-has-children/g, '');
      }
      menuElement.className = classes;
    }

    if (!favoritesEnabled) {
      return null;
    }

    if (!auth.userToken) {
      return (
        <AmelieSignInLink auth={auth} showForm={showForm} />
      );
    }

    return (
      <>
        {/* Must be a link because of WP */}
        <a>
          {intl.formatMessage({ id: 'amelieMenu.account' })}
        </a>
        <span className="mobile-sub-menu-toggle" />
        <ul className="sub-menu">
          <li>
            <AmelieSignOutLink auth={auth} signOut={signOut} />
            <span className="mobile-sub-menu-toggle" />
          </li>
        </ul>
      </>
    );
  }
}

AmelieMenu.propTypes = {
  // Properties.
  auth: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,

  // Functions.
  showForm: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

const mapDispatchToProps = (dispatch) => {
  const actionCreators = {
    ...authActions,
  };
  return bindActionCreators(actionCreators, dispatch);
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AmelieMenu));
// For tests.
export { AmelieMenu as PureAmelieMenu };
